import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import { loginProvider } from "../../../Config/Config";
import { ForgotPassword, Password, SignIn } from "../../../Constant";
import man from "../../../assets/images/dashboard/1.png";
import SocialAuth from "./SocialAuth";

const LoginTab = ({ selected }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [togglePassword, setTogglePassword] = useState(false);
	const history = useNavigate();
	const [error, setError] = useState("");
	const [roleId, setRoleId] = useState(null);
	const [value, setValue] = useState(
		sessionStorage.getItem("profileURL" || man)
	);
	const [name, setName] = useState(sessionStorage.getItem("Name"));
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const logUserIn = async (email) => {
		const result = await axios.get(
			`${API_BASE_URL}/Candidate/GetByEmail?email=${email}`
		);
		const roleIdResult = await axios.get(
			`${API_BASE_URL}/Company/GetRecruiterRoleId?email=${email}`
		);

		if (roleIdResult.data && roleIdResult.data.includes(4)) {
			setRoleId(1);
			sessionStorage.setItem("roleId", 1);
		} else {
			setRoleId(null);
			sessionStorage.removeItem("roleId");
		}
		sessionStorage.setItem("candidateId", result.data.candidateId);
		console.log(result.data.candidateId);
		sessionStorage.setItem("name", result.data.name);
		sessionStorage.setItem("surname", result.data.surname);
		sessionStorage.setItem("gender", result.data.gender);
		sessionStorage.setItem("jobTitle", result.data.currentJobTitle);
		setTimeout(() => {
			history(`/Edit-Profile`);
		}, 200);
	};

	useEffect(() => {
		sessionStorage.setItem("profileURL", value);
		sessionStorage.setItem("Name", name);
	}, [value, name]);

	window.onload = () => {
		sessionStorage.removeItem("token");
	};

	let handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			let res = await axios.post(
				`${API_BASE_URL}/Account/Login`,
				{
					email: email,
					password: password,
				},
				{ headers: { "Content-Type": "application/json" } }
			);
			if (res.status === 200) {
				loginProvider.signIn(email, password);
				setEmail("");
				setPassword("");
				sessionStorage.setItem("email", email);
				sessionStorage.setItem("token", res.data.token);
				logUserIn(email);
			}
		} catch (err) {
			let errorMessge = "Error Occured While Logging In.";
			toast.error(errorMessge, { position: toast.POSITION.TOP_CENTER });
		} finally {
			setLoading(false);
		}
	};

	return (
		<Fragment>
			<Form className="theme-form" onSubmit={handleSubmit}>
				<ToastContainer />
				<H4>Sign In</H4>
				<P>{"Enter your username & password to login"}</P>
				<FormGroup>
					<Label className="col-form-label">Email</Label>
					<Input
						className="form-control"
						type="text"
						required={true}
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						autoComplete="email"
					/>
				</FormGroup>
				<FormGroup className="position-relative">
					<Label className="col-form-label">{Password}</Label>
					<Input
						className="form-control"
						type={togglePassword ? "text" : "password"}
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						required={true}
						autoComplete="current-password"
					/>
					<div
						className="show-hide"
						onClick={() => setTogglePassword(!togglePassword)}
					>
						<span className={togglePassword ? "" : "show"}></span>
					</div>
				</FormGroup>
				<div className="form-group mb-0">
					<Link
						to={`${process.env.PUBLIC_URL}/forgot-password`}
						className="link"
					>
						{ForgotPassword}
					</Link>

					<Btn
						attrBtn={{
							color: "primary",
							className: "btn-block",
							disabled: loading ? loading : loading,
						}}
					>
						{loading ? "LOADING..." : SignIn}
					</Btn>
				</div>
				<SocialAuth />
			</Form>
		</Fragment>
	);
};
export default LoginTab;
