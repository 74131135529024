import JobDescription from "./jobDescription";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Card, Col, CardBody, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import {
	Breadcrumbs,
	H6,
	Image,
	P,
	Btn,
} from "../../../../../AbstractElements";

const BulletinMainCard = () => {
	const [data, setDate] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [rating, setRating] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	let url = window.location.href;
	let bulletinId = url.substring(url.lastIndexOf("/") + 1);

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/BulletinPost/${bulletinId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				const jobList = res.data;
				setDate(jobList);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<Fragment>
			<Container fluid={true} id="jobCard">
				<div className="job-container">
					<Col xl="9" className="col-xl-12 xl-100 box-col-12" id="job-card">
						<Card id="job-card">
							<div className="job-search">
								<CardBody>
									<div className="media">
										<div className="media-body">
											<H6 attrH6={{ className: "f-w-600" }}>
												<a href="#javascript">{data.messageHeader}</a>
											</H6>
											<P></P>
										</div>
									</div>
									<JobDescription />
								</CardBody>
							</div>
						</Card>
					</Col>
				</div>
			</Container>
		</Fragment>
	);
};

export default BulletinMainCard;
