import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { H6, Image, P } from "../../AbstractElements";

const ArticleRedirect = () => {
    document.body.style.backgroundColor = "#111727";
    const urlName = sessionStorage.getItem("redirectUrlName");
    const url = sessionStorage.getItem("redirectUrl");
    const articleId = sessionStorage.getItem("newsArticleId");
    const API_BASE_URL = window.appConfig.API_BASE_URL;

    useEffect(() => {
        (window.ADSENSE = window.ADSENSE || []).push({});

        const redirect = () => {
            window.location.href = url;
        };

        const timeoutId = setTimeout(redirect, 3000);
        return () => clearTimeout(timeoutId);
    }, [url]);

    const cardStyle = {
        backgroundColor: "transparent",
        backgroundImage: `url(${API_BASE_URL}/api/NewsArticles/FetchArticleThumbnail/${articleId})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "none",
        margin: "100px auto auto auto",
        width: "75%",
        top: "100",
        position: "relative",
    };

    const overlayStyle = {
        content: "",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    };

    const textContainerStyle = {
        position: "relative",
        zIndex: 1,
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Card style={cardStyle} className="image-background-card">
                    <div style={overlayStyle}></div>
                    <CardBody>
                        <div style={textContainerStyle}>
                            <h6 style={{ textAlign: "center", color: "#fff" }}>
                                Redirecting To
                            </h6>
                            <h4 style={{ textAlign: "center", color: "#fff" }}>{urlName}</h4>
                            <div className="loader-box txt-primary">
                                <div className="loader-7 txt-primary"></div>
                            </div>
                        </div>
                        <ins
                            className="ADSENSE"
                            data-ad-client="ca-pub-7778800188419604"
                            data-ad-slot="8126130974"
                            data-ad-format="rectangle"
                            data-full-width-responsive="true"
                        ></ins>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default ArticleRedirect;
