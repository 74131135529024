import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import {
	Breadcrumbs,
	H6,
	Image,
	P,
	Btn,
	LI,
	UL,
} from "../../../../../AbstractElements";
import "./style.css";

const BulletinDesc = () => {
	const [data, setDate] = useState([]);
	const [mustHaveData, setMustHave] = useState([]);
	const [skills, setSkills] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [rating, setRating] = useState([]);

	let url = window.location.href;
	let bulletinId = url.substring(url.lastIndexOf("/") + 1);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/BulletinPost/${bulletinId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				const jobList = res.data;
				setDate(jobList);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<Fragment>
			<div className="bulletin-description">
				<P attrPara={{ className: "text-start" }}>{data.messageBody}</P>
			</div>
		</Fragment>
	);
};

export default BulletinDesc;
