import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	FormGroup,
	Input,
	Col,
	Row,
} from "reactstrap";
import { FaEdit } from "react-icons/fa";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { parseISO, format } from "date-fns";
import DatePicker from "react-datepicker";

const ModalEdit = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [selectedValue, setSelectedValue] = useState([]);
	const candidatePositionId = props.candidatePositionId;
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [_inputValue, setValue] = useState([]);

	const [position, setPosition] = useState({
		candidateId: "",
		title: "",
		companyName: "",
		jobDescription: 0,
		startDate: format(new Date(), "yyyy-MM"),
		endDate: format(new Date(), "yyyy-MM"),
		active: "",
		createDate: "",
		mainSkillId: null,
	});

	const {
		candidateId,
		title,
		companyName,
		jobDescription,
		startDate,
		endDate,
		active,
		createDate,
		mainSkillId,
	} = position;

	const loadUserMainSkill = async () => {
		try {
			const result = await axios.get(
				`${API_BASE_URL}/CandidatePosition/${candidatePositionId}`
			);

			const skillNameResponse = await axios.get(
				`${API_BASE_URL}/Skillset/Skill/${result.data.mainSkillId}`
			);

			const selectedSkillset = result.data.mainSkillId;

			if (selectedSkillset) {
				const skillName = skillNameResponse.data;

				if (skillName) {
					const selectedOption = {
						skillsetId: selectedSkillset,
						name: skillName.name,
					};
					setSelectedValue(selectedOption);
				} else {
					setSelectedValue(null);
				}
			}
		} catch (error) {
			if (error.response && error.response.status === 400) {
			}
		}
	};

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/CandidatePosition/${candidatePositionId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				setPosition(res.data);
				loadUserMainSkill();
			})
			.catch((err) => {
				console.error(err);
			});
	}, [candidatePositionId]);

	const deleteHandler = async () => {
		try {
			toggle();
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	const getSavedskills = async (_inputValue) => {
		const res = await axios.get(
			`${API_BASE_URL}/CandidatePositionSkillset/GetCandidatePositionSkills/${candidatePositionId}`
		);
		return await res.data;
	};

	const promiseOptions = async (_inputValue) => {
		let savedSkillsTask = getSavedskills(selectedSkills);
		const res = await axios.get(`${API_BASE_URL}/Skillset`);
		let savedSkills = await savedSkillsTask;
		let allSkills = await res.data;
		setSelectedSkills(savedSkills);
		return allSkills;
	};

	const handleInputChange = (value) => {
		setValue(value);
	};

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	const handleInputChangeMain = (inputValue) => {
		setValue(inputValue);
	};

	const handleChangeMain = (selectedOption) => {
		setSelectedValue(selectedOption);
		const selectedSkillsetId = selectedOption
			? selectedOption.skillsetId
			: null;
		setPosition((prevUser) => ({
			...prevUser,
			mainSkillId: selectedSkillsetId,
		}));
	};

	const fetchData = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/Skillset`);
			const data = response.data;
			return data;
		} catch (error) {
			console.error(error);
		}
	};

	const onInputChange = (e) => {
		setPosition({ ...position, [e.target.name]: e.target.value });
	};

	const updatePosition = async () => {
		toggle();

		try {
			await axios.put(
				`${API_BASE_URL}/CandidatePosition/CandidatePosition/${candidatePositionId}`,
				position
			);

			const skillSetRequestBody = {
				candidatePositionId: candidatePositionId,
				skills: selectedSkills.map((e) => e.skillsetId),
			};

			await axios.post(
				`${API_BASE_URL}/CandidatePositionSkillset/AddCandidatePositionSkillset`,
				skillSetRequestBody
			);

			const newData = await axios.get(
				`${API_BASE_URL}/CandidatePosition/GetByCandidateId/${candidateId}`
			);

			const positionData = newData.data.map((item) => ({
				candidatePositionId: item.candidatePositionId,
				candidateId: item.candidateId,
				title: item.title,
				companyName: item.companyName,
				jobDescription: item.jobDescription,
				startDate: item.startDate,
				endDate: item.endDate,
				active: item.active,
				mainSkillId: selectedValue.skillsetId,
				mainSkillName: item.mainSkillName,
			}));

			props.updatePositionData(positionData);
			return directiontoaster("directionssuccessToast");
		} catch (err) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Position was updated", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an issue updating this position", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
		}
	};

	return (
		<div>
			<i className="icon-hover" id="work-experience-candidate">
				<FaEdit title="Edit" onClick={toggle} />
			</i>
			<Modal size="lg" isOpen={modal} toggle={toggle}>
				<ModalHeader>Update Work Experience</ModalHeader>
				<ModalBody style={{ height: "75vh" }}>
					<form onSubmit={updatePosition}>
						<FormGroup>
							<Row>
								<Col md="6">
									<Label className="form-label">
										Title <sup className="mandatory">*</sup>
									</Label>
									<Input
										type="email"
										name="title"
										id="exampleEmail"
										value={title}
										required
										className="form-control"
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
						</FormGroup>
						<FormGroup>
							<Row>
								<Col md="6">
									<Label className="form-label">
										Company Name <sup className="mandatory">*</sup>
									</Label>
									<Input
										type="email"
										name="companyName"
										id="exampleEmail"
										value={companyName}
										required
										className="form-control"
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
						</FormGroup>
						<FormGroup>
							<Row>
								<Col md="6">
									<Label className="form-label">
										Start Date <sup className="mandatory">*</sup>
									</Label>
									<DatePicker
										className="form-control"
										dateFormat="MM/yyyy"
										showMonthYearPicker
										selected={startDate ? parseISO(startDate) : null}
										onChange={(date) =>
											onInputChange({
												target: {
													name: "startDate",
													value: date ? format(date, "yyyy-MM-dd") : "",
												},
											})
										}
									/>
								</Col>
								<Col md="6">
									<Label className="form-label">
										End Date <sup className="mandatory">*</sup>
									</Label>
									<DatePicker
										className="form-control"
										dateFormat="MM/yyyy"
										showMonthYearPicker
										selected={endDate ? parseISO(endDate) : null}
										onChange={(date) =>
											onInputChange({
												target: {
													name: "endDate",
													value: date ? format(date, "yyyy-MM-dd") : "",
												},
											})
										}
									/>
								</Col>
							</Row>
						</FormGroup>
						<FormGroup>
							<Row>
								<Col md="12">
									<Label className="form-label">
										Job Description <sup className="mandatory">*</sup>
									</Label>
									<textarea
										type="text"
										rows="8"
										name="jobDescription"
										className="form-control"
										value={jobDescription}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</Row>
						</FormGroup>
						<FormGroup>
							<Label className="form-label">
								Skillset <sup className="mandatory">*</sup>
							</Label>
							<AsyncSelect
								key={candidateId}
								cacheOptions
								defaultOptions
								required
								selectOptions
								getOptionLabel={(e) => e.name}
								getOptionValue={(e) => e.skillsetId}
								value={selectedSkills}
								loadOptions={promiseOptions}
								onInputChange={handleInputChange}
								onChange={handleChange}
								isMulti={true}
								className="form-control"
								id="form-skill"
							/>
						</FormGroup>
						<FormGroup>
							<Label className="form-label">
								Main Skill <sup className="mandatory">*</sup>
							</Label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								value={selectedValue}
								getOptionLabel={(e) => e.name}
								getOptionValue={(e) => e.skillsetId}
								loadOptions={fetchData}
								onInputChange={handleInputChangeMain}
								onChange={handleChangeMain}
								isMulti={false}
								className="form-control"
								id="form-skill"
							/>
						</FormGroup>
					</form>
				</ModalBody>
				<ModalFooter>
					<Button id="cancel-mail" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" type="submit" onClick={updatePosition}>
						Update Position
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ModalEdit;
