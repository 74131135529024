import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
} from "reactstrap";
import { Btn, H4 } from "../../../../AbstractElements";
import ModalLink from "../../../Modal/Modal";
import ModalDelete from "../../../ModalDelete/Modal";
import ModalEdit from "../../../ModalEdit/Modal";
import Provinces from "../../../TableData/Provinces";
import equity from "../../../TableData/equity";
import experience from "../../../TableData/experience";
import genders from "../../../TableData/genders";
import salary from "../../../TableData/salary";
import "./style.css";

const EditCandidateProfile = () => {
	const candidateId = sessionStorage.getItem("candidateId");
	const [positionData, setPositionData] = useState([]);
	const [_inputValue, setValue] = useState([]);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [selectedValue, setSelectedValue] = useState([]);
	const [file, setFile] = useState("");
	const ref = useRef("");
	const [username, setUsername] = useState("");
	const [reward, setReward] = useState([]);
	const [candidateDocument, setCandidateDocument] = useState({
		documentName: null,
		hasDocument: false,
	});

	const [user, setUser] = useState({
		name: "",
		surname: "",
		cell: "",
		email: "",
		gender: "",
		yearsExperience: "",
		currentSalary: "",
		ee: "",
		selectedSkills: [],
		idNumber: "",
		currentCompany: "",
		currentJobTitle: "",
		notes: "string",
		linkedIn: "",
		city: "",
		province: "",
		bio: "",
		mainSkillsetId: null,
		active: 1,
	});

	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const {
		name,
		surname,
		cell,
		email,
		yearsExperience,
		currentSalary,
		gender,
		idNumber,
		ee,
		currentCompany,
		currentJobTitle,
		notes,
		linkedIn,
		city,
		province,
		bio,
		mainSkillsetId,
		active,
	} = user;

	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState([]);

	useEffect(() => {
		fetchQuestionsAndAnswers();
	}, []);

	const fetchQuestionsAndAnswers = async () => {
		try {
			const [questionsResponse, answersResponse] = await Promise.all([
				axios.get(`${API_BASE_URL}/CandidateQuestionTemplate`),
				axios.get(`${API_BASE_URL}/CandidateAnswer/${candidateId}`),
			]);

			const questionsData = questionsResponse.data;
			const answersData = answersResponse.data;

			setQuestions(questionsData);

			const initialAnswers = questionsData.map((question) => ({
				questionId: question.id,
				answer: "",
			}));

			setAnswers(answersData.length > 0 ? answersData : initialAnswers);
		} catch (error) {
			console.error("Failed to fetch questions and answers:", error);
		}
	};
	const handleInputChangeMain = (inputValue) => {
		setValue(inputValue);
	};

	const handleChangeMain = (selectedOption) => {
		setSelectedValue(selectedOption);
		const selectedSkillsetId = selectedOption
			? selectedOption.skillsetId
			: null;
		setUser((prevUser) => ({
			...prevUser,
			mainSkillsetId: selectedSkillsetId,
		}));
	};

	const fetchData = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/Skillset`);
			const data = response.data;
			return data;
		} catch (error) {
			console.error(error);
		}
	};

	const loadUser = async () => {
		const email = sessionStorage.getItem("email");

		setUsername(email);

		const result = await axios.get(
			`${API_BASE_URL}/Candidate/GetByEmail?email=${email}`
		);

		console.log(result);

		setUser(result.data);

		try {
			const skillName = await axios.get(
				`${API_BASE_URL}/Skillset/Skill/${result.data.mainSkillsetId}`
			);

			const selectedSkillset = result.data.mainSkillsetId;

			if (selectedSkillset) {
				const selectedOption = {
					skillsetId: selectedSkillset,
					name: skillName.data.name,
				};
				setSelectedValue(selectedOption);
			}
		} catch (error) {
			console.error(
				"An error occurred while fetching skillName:",
				error.message
			);
			setSelectedValue(null);
		}
	};

	const loadUserBadges = async (candidateId) => {
		const res = await axios
			.get(`${API_BASE_URL}/Candidate/${candidateId}/reward`)
			.then((response) => {
				setReward(response.data);
			});
	};

	const onInputChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
		if (e.target.type === "file") {
			setFile(e.target.files[0]);
		}
	};

	useEffect(() => {
		const source = axios.CancelToken.source();

		loadUser();
		loadDocumentData(candidateId);
		loadUserBadges(candidateId);
		getCandidatePositions();

		return () => {
			source.cancel();
		};
	}, [username, candidateId]);

	const getSavedskills = async (_inputValue) => {
		const res = await axios.get(
			`${API_BASE_URL}/CandidateSkillset/${candidateId}`
		);
		return await res.data;
	};

	const promiseOptions = async (_inputValue) => {
		let savedSkillsTask = getSavedskills(selectedSkills);
		const res = await axios.get(`${API_BASE_URL}/Skillset`);
		let savedSkills = await savedSkillsTask;
		let allSkills = await res.data;
		setSelectedSkills(savedSkills);
		return allSkills;
	};

	const handleInputChange = (value) => {
		setValue(value);
	};

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	const handleChanges = (event, index) => {
		if (event.target.files && event.target.files.length > 0) {
			setFile(event.target.files[0]);
		}

		if (event.target.tagName === "INPUT" && event.target.type === "file") {
			return;
		}

		const newAnswers = [...answers];
		if (index >= 0 && index < questions.length) {
			const updatedAnswer = {
				...newAnswers[index],
				answer: event.target.value,
			};
			newAnswers[index] = updatedAnswer;
			setAnswers(newAnswers);
		} else {
			console.error("Invalid answer index:", index);
		}
	};

	const submitAnswers = async () => {
		try {
			if (answers.length !== questions.length) {
				console.error("Number of answers does not match number of questions");
				return;
			}

			const answerSubmission = {
				answers: answers.map((answer) => answer?.answer || ""),
				candidateId: candidateId,
				templateIds: questions.map(
					(question) => question.candidateQuestionTemplateId
				),
			};

			const existingAnswers = answers.filter(
				(answer) => answer.id !== undefined && answer.id !== null
			);

			if (existingAnswers.length > 0) {
				await axios.put(`${API_BASE_URL}/CandidateAnswer/${candidateId}`, {
					...answerSubmission,
					answers: existingAnswers.map((answer) => ({
						...answer,
						answer: answer.answer,
					})),
				});
			} else {
				await axios.post(`${API_BASE_URL}/CandidateAnswer`, answerSubmission);
			}
		} catch (error) {
			console.error("Failed to submit answers:", error);
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		e.currentTarget.disabled = true;

		if (
			name !== "" &&
			surname !== "" &&
			cell !== "" &&
			gender !== "" &&
			yearsExperience !== "" &&
			currentSalary !== "" &&
			ee !== "" &&
			selectedSkills !== "" &&
			idNumber !== "" &&
			currentJobTitle !== "" &&
			selectedSkills.length !== 0
		) {
			try {
				if (file) {
					uploadCandidateDocument(candidateId);
				}
				user.active = true;
				await uploadAvatar();
				await axios.put(`${API_BASE_URL}/Candidate/${candidateId}`, user);

				const skillSetRequestBody = {
					candidateId: candidateId,
					skills: selectedSkills.map((e) => e.skillsetId),
				};

				await axios.post(
					`${API_BASE_URL}/CandidateSkillset/AddCandidateSkillset`,
					skillSetRequestBody
				);
				await submitAnswers();
				setTimeout(function () {
					window.location.href = "Edit-Profile";
				}, 1500);

				return directiontoaster("directionssuccessToast");
			} catch (err) {
				let errorMessge = err.toString();
				toast.error(errorMessge, { position: toast.POSITION.TOP_CENTER });
			}
		}
	};

	const loadDocumentData = () => {
		axios
			.get(`${API_BASE_URL}/CandidateDocument/DocumentDetails/${candidateId}`)
			.then((response) => {
				let [documentName, hasDocument] = ["N/A", false];
				if (typeof response.data === "object") {
					documentName = response.data.documentName;
					hasDocument = true;
				}
				setCandidateDocument({ documentName, hasDocument });
			})
			.catch((error) => console.log({ error }));
	};

	const uploadCandidateDocument = () => {
		const url = `${API_BASE_URL}/CandidateDocument/AddCandidateDocument/${candidateId}`;
		const formData = new FormData();
		formData.append("file", file);
		formData.append("fileName", file.name);

		const validFileTypes = [
			"application/pdf",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		];

		if (validFileTypes.indexOf(file.type) < 0) {
			alert("Please select either a PDF or a Word document", {
				type: "filetype",
				message: "Only PDFs are valid.",
			});
			return;
		}

		const config = {
			headers: {},
		};
		axios.post(url, formData, config).then((response) => {});
	};

	const [image, setImage] = useState("");

	function handleImage(e) {
		setImage(e.target.files[0]);
	}

	async function uploadAvatar(err) {
		if (!image) {
			return false;
		}
		if (!image.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
			throw Error("select valid image.");
		}

		const formData = new FormData();
		formData.append("formFile", image);
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		await axios.post(
			`${API_BASE_URL}/Candidate/AddAvatar?candidateId=${candidateId}`,
			formData,
			config
		);
	}

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Profile updating...", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
			case "directionsdangerToast":
				toast.error("Please fill in all the fields", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
		}
	};

	const getCandidatePositions = async () => {
		try {
			const res = await axios
				.get(
					`${API_BASE_URL}/CandidatePosition/GetByCandidateId/${candidateId}`
				)
				.then((response) => {
					setPositionData(response.data);
				});
		} catch (e) {
			console.error(e.message);
		}
	};

	const updatePositionData = (newData) => {
		setPositionData(newData);
		console.log(newData);
	};

	const deleteOpenPosition = async (id) => {
		const res = await axios.delete(
			`${API_BASE_URL}/CandidatePosition/CandidatePosition/${id}`
		);
	};

	const [collapse, setCollapse] = useState(false);

	const toggle = () => {
		setCollapse(!collapse);
	};

	return (
		<Fragment>
			<Row>
				<Form id="form-card" onSubmit={onSubmit} style={{ width: "100%" }}>
					<Col xl="12">
						<Row>
							<div className="outer-container">
								{reward.map(
									({ description, badgeImage, candidateRewardId }) => {
										return (
											<div key={candidateRewardId} className="inner-container">
												<div className="reward-container">
													<Row id="item-badge-row">
														<Link
															to={`${process.env.PUBLIC_URL}/Candidate/${candidateId}/reward/${candidateRewardId}`}
														>
															<img
																src={badgeImage}
																className="candidate-reward-badge"
																alt={`Badge for ${description}`}
															/>
														</Link>
													</Row>
													<Row>
														<Label
															className="form-label form-label"
															id="badge-title"
														>
															{description}
														</Label>
													</Row>
												</div>
											</div>
										);
									}
								)}
							</div>
						</Row>
						<Card>
							<CardHeader className="pb-0">
								<H4 attrh4={{ className: "card-title mb-0" }}>
									Personal Details
								</H4>
								<Label className="form-label" style={{ marginTop: "8px" }}>
									Please update the fields below with your information. Your
									profile will be used when applying for positions or
									opportunities on the website. Visit us frequently to keep your
									information up to date.
								</Label>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="6">
										<FormGroup className="mb-3">
											<Label className="form-label">
												First Name <sup className="mandatory">*</sup>
											</Label>
											<Input
												className="form-control"
												required
												type="text"
												name="name"
												id="exampleEmail"
												value={name}
												onChange={(e) => onInputChange(e)}
											/>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												Surname <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="text"
												name="surname"
												required
												id="examplePassword"
												value={surname}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											/>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												ID Number <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="text"
												minLength="13"
												maxLength="13"
												name="idNumber"
												id="idNumber"
												className="form-control"
												value={idNumber}
												onChange={(e) => onInputChange(e)}
											></Input>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												Gender <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="select"
												id="exampleSelect"
												name="gender"
												value={gender}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											>
												<option key=""></option>
												{genders.map((sex) => (
													<option key={sex}>{sex}</option>
												))}
											</Input>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												City <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="text"
												id="exampleSelect"
												required
												name="city"
												value={city}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											/>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												Province <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="select"
												id="exampleSelect"
												required
												name="province"
												value={province}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											>
												<option key=""></option>
												{Provinces.map((pronvince) => (
													<option key={pronvince}>{pronvince}</option>
												))}
											</Input>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
						<Card>
							<CardHeader className="pb-0">
								<H4 attrh4={{ className: "card-title mb-0" }}>About</H4>
							</CardHeader>
							<CardBody>
								<Col sm="12" md="12">
									<Label className="form-label">Bio</Label>
									<textarea
										type="text"
										rows="10"
										name="bio"
										className="form-control"
										id="form-notes"
										value={bio}
										onChange={(e) => onInputChange(e)}
									/>
								</Col>
							</CardBody>
						</Card>
						<Card>
							<CardHeader className="pb-0">
								<H4 attrh4={{ className: "card-title mb-0" }}>
									Contact Details
								</H4>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="6">
										<FormGroup>
											<Label className="form-label">Email</Label>
											<Input
												type="email"
												name=""
												id="exampleEmail"
												value={email}
												required
												onChange={(e) => onInputChange(e)}
												className="form-control"
												readOnly
											/>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												Cellphone Number <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="text"
												minLength="10"
												maxLength="10"
												required
												name="cell"
												id="exampleCell"
												value={cell}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											/>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">LinkedIn URL</Label>
											<Input
												type="text"
												name="linkedIn"
												value={linkedIn}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											/>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
						<Card>
							<CardHeader className="pb-0">
								<h4
									attrh4={{ className: "card-title mb-0" }}
									style={{
										display: "flex",
									}}
								>
									Current Work Position
								</h4>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="6">
										<FormGroup>
											<Label className="form-label">
												Job Title <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="text"
												name="currentJobTitle"
												id="jobTitle"
												required
												value={currentJobTitle}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											/>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">Current Company</Label>
											<Input
												type="text"
												name="currentCompany"
												id="exampleEmail"
												value={currentCompany}
												className="form-control"
												onChange={(e) => onInputChange(e)}
												placeholder="Leave blank if unemployed"
											/>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												Current Salary <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="select"
												id="exampleSelect"
												name="currentSalary"
												required
												className="form-control"
												value={currentSalary}
												onChange={(e) => onInputChange(e)}
											>
												<option key=""></option>
												{salary.map((wages) => (
													<option key={wages}>{wages}</option>
												))}
											</Input>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												Employment Equity <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="select"
												id="exampleSelect"
												name="ee"
												className="form-control"
												value={ee}
												onChange={(e) => onInputChange(e)}
											>
												<option key=""></option>
												{equity.map((employment) => (
													<option key={employment}>{employment}</option>
												))}
											</Input>
										</FormGroup>
									</Col>
									<Col sm="6" md="6">
										<FormGroup>
											<Label className="form-label">
												Years Of Experience <sup className="mandatory">*</sup>
											</Label>
											<Input
												type="select"
												name="yearsExperience"
												id="yearsExperience"
												required
												value={yearsExperience}
												className="form-control"
												onChange={(e) => onInputChange(e)}
											>
												<option key=""></option>
												{experience.map((years) => (
													<option key={years}>{years}</option>
												))}
											</Input>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
						<div>
							<Card style={{ padding: "20px" }}>
								<div className="position-container">
									<div className="inner-position">
										<Button
											color="primary"
											onClick={toggle}
											style={{ width: "fit-content" }}
										>
											View Previous Job Positions
										</Button>
									</div>
									<div className="inner-position">
										<ModalLink updatePositionData={updatePositionData} />
									</div>
								</div>
							</Card>
							<Collapse isOpen={collapse}>
								{positionData.map((position, index) => (
									<Card key={index}>
										<CardBody>
											<Row>
												<div className="header-row-position">
													<h5 id="experience-company">
														<b>{position.companyName}</b>
													</h5>
													<div className="position-actions">
														<ModalDelete
															deleteMessage={`Are you sure you want to delete the position with ${position.companyName}?`}
															onDelete={() =>
																deleteOpenPosition(position.candidatePositionId)
															}
															updatePositionData={updatePositionData}
														/>
														<ModalEdit
															candidatePositionId={position.candidatePositionId}
															updatePositionData={updatePositionData}
														/>
													</div>
												</div>
												<p>
													{position.startDate.slice(0, 7)} -{" "}
													{position.endDate.slice(0, 7)}
												</p>
											</Row>
											<hr id="experience-hr" />
											<h6 id="experience-job">
												{position.title} - {position.mainSkillName}
											</h6>
											<p>{position.jobDescription}</p>
										</CardBody>
									</Card>
								))}
							</Collapse>
						</div>

						<Card>
							<CardHeader className="pb-0">
								<H4 attrh4={{ className: "card-title mb-0" }}>Your Skills</H4>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="12">
										<FormGroup>
											<Label className="form-label">
												Skillset <sup className="mandatory">*</sup>
											</Label>
											<AsyncSelect
												key={candidateId}
												cacheOptions
												defaultOptions
												required
												selectOptions
												getOptionLabel={(e) => e.name}
												getOptionValue={(e) => e.skillsetId}
												value={selectedSkills}
												loadOptions={promiseOptions}
												onInputChange={handleInputChange}
												onChange={handleChange}
												isMulti={true}
												className="form-control"
												id="form-skill"
											/>
										</FormGroup>
									</Col>
									<Col md="12">
										<FormGroup>
											<Label className="form-label">
												Main Skill <sup className="mandatory">*</sup>
											</Label>
											<AsyncSelect
												cacheOptions
												defaultOptions
												value={selectedValue}
												getOptionLabel={(e) => e.name}
												getOptionValue={(e) => e.skillsetId}
												loadOptions={fetchData}
												onInputChange={handleInputChangeMain}
												onChange={handleChangeMain}
												isMulti={false}
												className="form-control"
												id="form-skill"
											/>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
						<Card>
							<CardHeader className="pb-0">
								<H4 attrh4={{ className: "card-title mb-0" }}>
									Upload Documents
								</H4>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="12">
										<FormGroup>
											<div className="pfp-upload">
												<Label for="exampleSelect">
													Upload Profile Picture
												</Label>
												<input
													type="file"
													name="file"
													onChange={handleImage}
													className="form-control"
													accept=".png, .jpg, .jpeg, .tiff"
												/>
											</div>
										</FormGroup>
									</Col>
									<Col md="12">
										<FormGroup>
											<div className="cv-upload">
												<Label for="exampleSelect">
													Upload CV | Document Name:{" "}
													<sup className="mandatory">* </sup>
													{candidateDocument.documentName}
												</Label>
												<input
													type="file"
													className="form-control"
													required={!candidateDocument.hasDocument}
													ref={ref}
													onChange={handleChanges}
													accept=".pdf, .doc, .docx"
												/>
											</div>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
						<Card>
							<CardHeader className="pb-0">
								<H4 attrh4={{ className: "card-title mb-0" }}>Questions</H4>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="12">
										<FormGroup>
											{questions.map((question, index) => (
												<div
													key={question.candidateQuestionTemplateId}
													className="cv-upload"
												>
													<Label
														for={`question-${question.candidateQuestionTemplateId}`}
													>
														{`Question ${index + 1}: ${
															question.candidateQuestion
														}?`}
													</Label>
													<textarea
														type="text"
														rows="3"
														className="form-control"
														id={`question-${question.candidateQuestionTemplateId}`}
														value={answers[index]?.answer || ""}
														onChange={(event) => handleChanges(event, index)}
													/>
												</div>
											))}
										</FormGroup>
									</Col>

									<Col md="12">
										<FormGroup>
											<Btn
												attrBtn={{
													color: "primary",
													type: "submit",
													id: "update-btn",
												}}
											>
												Update Profile
											</Btn>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Form>
			</Row>
		</Fragment>
	);
};
export default EditCandidateProfile;
