import React from "react";
import { Route, Routes } from "react-router-dom";
import BlogDetail from "../Components/Blog/BlogDetail";
import AppLayout from "../Layout/Layout";
import { routes } from "./Routes";

const LayoutRoutes = () => {
	return (
		<>
			<Routes>
				{routes.map(({ path, Component }, i) => (
					<Route element={<AppLayout />} key={i}>
						<Route path={path} element={Component} />
					</Route>
				))}
				<Route path="/NewsFeed" element={<AppLayout />}>
					<Route index element={<BlogDetail />} />
				</Route>
			</Routes>
		</>
	);
};

export default LayoutRoutes;
