import RegisterSimple from "../Components/Pages/Auth/RegisterSimple";
import ForgotPassword from "../Auth/Tabs/LoginTab/ForgotPassword";
import ResetPassword from "../Auth/Tabs/LoginTab/ResetPassword";
import ForgetPwd from "../Components/Pages/Auth/ForgetPwd";
import ResetPwd from "../Components/Pages/Auth/ResetPwd";

export const authRoutes = [
	// { path: "reset-password", Component: <ResetPassword /> },
	// { path: "register", Component: <RegisterSimple /> },
	// { path: "forgot-password", Component: <ForgotPassword /> },
	{ path: "reset-password", Component: <ResetPwd /> },
	{ path: "register", Component: <RegisterSimple /> },
	{ path: "forgot-password", Component: <ForgetPwd /> },
];
