import axios from "axios";
import React, { Fragment, useEffect } from "react";
import { Google } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Btn } from "../../../AbstractElements";

const GoogleCus = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const history = useNavigate();

	return (
		<Fragment>
			<a
				href={
					"https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77l1svdsgxg7c8&redirect_uri=https://skilledup.co.za/login&state=987654321&scope=openid,profile,w_member_social,email"
				}
			>
				<Btn
					attrBtn={{
						color: "dark",
						onClick: () => {},
						id: "google-login-btn"
					}}
				>
					<i className="fa fa-google txt-googleplus"></i>
				</Btn>
			</a>
		</Fragment>
	);
};

export default GoogleCus;
