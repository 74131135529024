import axios from "axios";
import { decode } from "html-entities";
import React, { Fragment, useEffect, useState } from "react";
import { FaHandPaper } from "react-icons/fa";
import { Card, Col, Container, Row } from "reactstrap";
import { Btn, H6, Image, LI, UL } from "../../../AbstractElements";
import ModalAlert from "../../ModalAlert/Modal";
import "./styles.css";

const Gallery = () => {
	const [articles, setArticles] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [pageLoaded, setPageLoaded] = useState(false);
	const candidateId = sessionStorage.getItem("candidateId");
	const [likedArticles, setLikedArticles] = useState([]);
	const [page, setPage] = useState(1);
	const [hasMoreArticles, setHasMoreArticles] = useState(true);
	const [showModal, setShowModal] = useState(false);

	const redirectToArticle = (url, newsArticleId) => {
		incrementVisitCount(newsArticleId);
		window.open(url, "_blank");
	};

	const fetchArticles = async () => {
		try {
			const res = await axios.get(
				`${API_BASE_URL}/api/NewsArticles/GetAllNewsArticles`
			);

			const fetchedArticles = res.data;

			if (fetchedArticles.length === 0) {
				setHasMoreArticles(false);
				return;
			}

			setArticles((prevItems) => {
				const allFetchedArticles = [...prevItems, ...fetchedArticles];
				const articlesStartingFromIndex3 = allFetchedArticles.slice(3);
				return articlesStartingFromIndex3;
			});
		} catch (error) {
			console.error("Error fetching articles:", error);
		}
	};

	useEffect(() => {
		fetchArticles();
	}, []);

	useEffect(() => {
		let isMounted = true;

		const fetchLikedArticles = async () => {
			try {
				const res = await axios.get(
					`${API_BASE_URL}/NewsArticleLike/GetLikedArticles?candidateId=${candidateId}`
				);

				if (isMounted) {
					const likedArticleIds = res.data.map(
						(article) => article.newsArticleId
					);
					setLikedArticles(likedArticleIds);
				}
			} catch (error) {
				console.error("Error fetching liked articles:", error);
			}
		};

		fetchLikedArticles();

		return () => {
			isMounted = false;
		};
	}, [candidateId]);

	const cleanUpSummary = (summary) => {
		const decodedSummary = decode(summary);
		const textOnly = decodedSummary.replace(/<[^>]+>/g, "");
		const cleanedSummary = textOnly.replace(/[^\w\s\d]/g, "");
		const wordsArray = cleanedSummary.trim().split(/\s+/);
		const first15Words = wordsArray.slice(0, 15).join(" ");
		return first15Words;
	};

	function getDayAndShortMonth(dateString) {
		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		const date = new Date(dateString);
		const day = date.getDate();
		const month = months[date.getMonth()];

		return `${day} ${month}`;
	}

	const incrementVisitCount = async (
		newsArticleId,
		redirectUrl,
		articleName
	) => {
		try {
			const url = `/Redirect`;
			sessionStorage.setItem("redirectUrl", `${redirectUrl}`);
			sessionStorage.setItem("newsArticleId", `${newsArticleId}`);
			sessionStorage.setItem("redirectUrlName", `${articleName}`);

			window.open(url, "_blank");
			const correctedCandidateId = candidateId === null ? 1410 : candidateId;

			await axios.post(
				`${API_BASE_URL}/api/NewsArticleVisits/ArticleVisits?candidateId=${correctedCandidateId}&newsArticleId=${newsArticleId}`
			);

			setArticles((prevArticles) =>
				prevArticles.map((article) =>
					article.newsArticleId === newsArticleId
						? { ...article, visitCount: article.visitCount + 1 }
						: article
				)
			);
		} catch (error) {
			console.error("Error incrementing visit count:", error);
		}
	};

	const likeArticle = async (newsArticleId) => {
		try {
			if (!candidateId) {
				setShowModal(true);
				return;
			}

			const isLiked = likedArticles.includes(newsArticleId);

			if (isLiked) {
				await axios.post(
					`${API_BASE_URL}/NewsArticleLike/LikeArticle?candidateId=${candidateId}&newsArticleId=${newsArticleId}`
				);
			} else {
				await axios.post(
					`${API_BASE_URL}/NewsArticleLike/LikeArticle?candidateId=${candidateId}&newsArticleId=${newsArticleId}`
				);
			}

			setLikedArticles((prevLikedArticles) => {
				if (prevLikedArticles.includes(newsArticleId)) {
					return prevLikedArticles.filter(
						(articleId) => articleId !== newsArticleId
					);
				} else {
					return [...prevLikedArticles, newsArticleId];
				}
			});

			setArticles((prevArticles) =>
				prevArticles.map((article) =>
					article.newsArticleId === newsArticleId
						? {
								...article,
								likeCount: isLiked
									? article.likeCount - 1
									: article.likeCount + 1,
						  }
						: article
				)
			);
		} catch (error) {
			console.error("Error liking/unliking article:", error);
		}
	};

	const filterArticles = async (articleName) => {
		try {
			const encodedArticleName = encodeURIComponent(articleName);
			const res = await axios.get(
				`${API_BASE_URL}/api/NewsArticles/GetNewsArticlesByTag?tagName=${encodedArticleName}`
			);
			setArticles(res.data);
			setPageLoaded(true);
		} catch (error) {
			console.error("Error fetching articles:", error);
		}
	};

	return (
		<Fragment>
			<Container fluid={true}>
				<Btn
					attrBtn={{
						color: "primary",
						className: "cancel-btn m-t-10 news-filter-btn",
						onClick: fetchArticles,
					}}
				>
					Clear Filter
				</Btn>
			</Container>
			<ModalAlert
				showModal={showModal}
				setShowModal={setShowModal}
				postMessage={postMessage}
			/>
			<Row>
				{articles.length > 0 ? (
					articles.map((item, index) => (
						<Col md="6" xl="3" className="box-col-6 xl-50" key={index}>
							<Card>
								<div className="blog-box blog-grid text-center">
									<a
										rel="noopener noreferrer"
										onClick={() =>
											incrementVisitCount(
												item.newsArticleId,
												item.url,
												item.title
											)
										}
										style={{ color: "#000" }}
									>
										<Image
											attrImage={{
												className: "img-fluid top-radius-blog blog-images",
												src: `${API_BASE_URL}/api/NewsArticles/FetchArticleThumbnail/${item.newsArticleId}`,
												alt: "",
											}}
										/>
									</a>
									<div className="blog-details-main">
										<H6
											attrH6={{
												className: "blog-bottom-details blog-header-tag",
											}}
										>
											<a
												rel="noopener noreferrer"
												onClick={() =>
													incrementVisitCount(
														item.newsArticleId,
														item.url,
														item.title
													)
												}
												style={{ color: "#98a6ad", cursor: "pointer" }}
											>
												{item.title}
											</a>
										</H6>
										<div className="gallery-blog-p">
											<a
												rel="noopener noreferrer"
												onClick={() => incrementVisitCount(item.newsArticleId)}
												style={{ color: "#98a6ad", cursor: "pointer" }}
											>
												<p style={{ color: "#999", textAlign: "left" }}>
													{cleanUpSummary(item.summary)}...{" "}
												</p>
											</a>
											<br />
											<p style={{ color: "#999" }} className="blog-p-tags">
												{item.categoryName}
											</p>
										</div>
										<div style={{ textAlign: "right", padding: "5px" }}>
											{item.tags.map((tag, index) => (
												<a
													key={index}
													onClick={() => filterArticles(tag)}
													className="article-tag"
													style={{
														display: "inline-block",
														border: "none",
														textAlign: "right",
														textDecoration: "none",
														padding: "5px",
														borderRadius: "7px",
														fontSize: "10px",
														marginLeft: "5px",
														marginRight: "5px",
														backgroundColor: "rgba(0, 180, 180, 0.2)",
														color: "#fff",
													}}
												>
													{tag}
												</a>
											))}
										</div>
										<hr
											style={{
												color: "rgba(0, 180, 180, 0.2)",
											}}
										/>
										<UL
											attrUL={{ className: "blog-social" }}
											style={{ marginTop: "100%" }}
										>
											<LI attrli={{ className: "digits blog-bottom-li" }}>
												<p style={{ color: "#999" }}>
													{getDayAndShortMonth(item.createDate)}
												</p>
											</LI>
											<LI attrli={{ className: "digits blog-bottom-li" }}>
												<p style={{ color: "#999" }}>
													<i
														className={
															likedArticles.includes(item.newsArticleId)
																? "icofont icofont-thumbs-up liked"
																: "icofont icofont-thumbs-up"
														}
														onClick={() => likeArticle(item.newsArticleId)}
													></i>
													{item.likeCount === 1
														? `${item.likeCount} Like`
														: `${item.likeCount} Likes`}
												</p>
											</LI>
											<LI attrli={{ className: "digits blog-bottom-li" }}>
												<p style={{ color: "#999" }}>
													<FaHandPaper style={{ marginRight: "5px" }} />
													{item.visitCount} Hits
												</p>
											</LI>
										</UL>
									</div>
								</div>
							</Card>
						</Col>
					))
				) : (
					<p></p>
				)}
			</Row>
		</Fragment>
	);
};

export default Gallery;
