import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Container } from "reactstrap";
import { BreadcrumbsNew, Btn } from "../../AbstractElements";
import "./styles.css";

const SkillTestConfirmation = (e) => {
	let url = window.location.href;
	let testId = url.substring(url.lastIndexOf(":") + 1);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [testName, setTestName] = useState("");
	const [testLength, setTestLength] = useState("");
	const [pageLoaded, setPageLoaded] = useState(false);

	useEffect(() => {
		let isMounted = true;
		axios
			.get(`${API_BASE_URL}/SkillsTest/WIthQuestions/${testId}`)
			.then((res) => {
				if (isMounted) {
					setTestName(res.data.name);
					setTestLength(res.data.questions.length);
					setPageLoaded(true);
				}
			});
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<Fragment>
			<Container fluid={true}>
				<BreadcrumbsNew title="Assessment" mainTitle="Assessment" />
				{!pageLoaded && (
					<div className="loader-box txt-primary">
						<div className="loader-7 txt-primary"></div>
					</div>
				)}
				{pageLoaded && (
					<Card id="test-confirm-card">
						<br />
						<h4 id="test-confirm-header">
							Please prepare to start your test for {testName}
						</h4>
						<br />
						<p id="test-confirm-body">
							There are {testLength} multiple choice questions.
							<br /> <br />
							The test must be completed in one session.
						</p>
						<br />
						<div className="test-buttons">
							<Link to={`/Skills-List`}>
								<Btn
									attrBtn={{
										className: "btn text-center cancel-test",
										type: "button",
									}}
								>
									Cancel Test
								</Btn>
							</Link>

							<Link to={`/Skill-Test:${testId}`}>
								<Btn
									attrBtn={{
										className: "btn text-center start-test",
										color: "primary",
										type: "button",
									}}
								>
									Start Test
								</Btn>
							</Link>
						</div>
						<br />
						<br />
					</Card>
				)}
			</Container>
		</Fragment>
	);
};

export default SkillTestConfirmation;
