import React, { Fragment, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, P } from "../../../AbstractElements";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ResetPwd = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [togglePassword, setTogglePassword] = useState(false);
	const [togglePasswordConfirm, setTogglePasswordConfirm] = useState(false);

	const url = window.location.href;
	const index = url.lastIndexOf("=");
	const token = url.slice(index + 1);

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("...", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
			case "directionsdangerToast":
				toast.error("The passwords do not match!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1500,
				});
				break;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (password !== confirmPassword) {
			directiontoaster("directionsdangerToast");
			return;
		}
		try {
			const res = await axios.post(
				`${API_BASE_URL}/Account/reset-password`,
				{
					Email: email,
					NewPassword: password,
					ConfirmPassword: confirmPassword,
					ResetToken: token,
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			if (res.status === 200) {
				setPassword("");
				setConfirmPassword("");
				toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
				navigate("/login");
			}
		} catch (err) {
			let errorMessage = "Error occurred while resetting password.";
			errorMessage = err?.response?.data?.message ?? errorMessage;
			toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
		} finally {
			setLoading(false);
		}
	};

	return (
		<Fragment>
			<section>
				<Container className="p-0" fluid={true}>
					<Row className="m-0">
						<Col className="p-0">
							<div className="login-card">
								<div className="login-main" id="forgot-password">
									<Form
										className="theme-form login-form"
										onSubmit={handleSubmit}
									>
										<H4 attrH4={{ className: "mb-3" }}>Reset Your Password</H4>
										<FormGroup>
											<Label>Email Address</Label>
											<Row>
												<Col xl="12" sm="3">
													<Input
														className="form-control"
														type="email"
														placeholder="johndoe@gmail.com"
														required={true}
														onChange={(e) => setEmail(e.target.value)}
														value={email}
													/>
												</Col>
											</Row>
										</FormGroup>
										<FormGroup className="position-relative">
											<Label>New Password</Label>
											<div className="input-group">
												<span className="input-group-text">
													<i className="icon-lock"></i>
												</span>
												<Input
													className="form-control"
													type={togglePassword ? "text" : "password"}
													required={true}
													minLength={8}
													onChange={(e) => setPassword(e.target.value)}
													value={password}
													placeholder="*********"
												/>
												<div
													className="show-hide"
													onClick={() => setTogglePassword(!togglePassword)}
												>
													<span className={togglePassword ? "" : "show"}></span>
												</div>
											</div>
										</FormGroup>
										<FormGroup>
											<Label>Confirm Password</Label>
											<div className="input-group">
												<span className="input-group-text">
													<i className="icon-lock"></i>
												</span>
												<Input
													className="form-control"
													type={togglePasswordConfirm ? "text" : "password"}
													required={true}
													minLength={8}
													onChange={(e) => setConfirmPassword(e.target.value)}
													value={confirmPassword}
													placeholder="*********"
												/>
												<div
													className="show-hide"
													onClick={() =>
														setTogglePasswordConfirm(!togglePasswordConfirm)
													}
												>
													<span
														className={togglePasswordConfirm ? "" : "show"}
													></span>
												</div>
											</div>
										</FormGroup>
										<FormGroup className="m-t-50">
											<Btn
												attrBtn={{
													className: "btn-block",
													color: "primary",
													type: "submit",
												}}
											>
												Reset Password
											</Btn>
										</FormGroup>

										<P>
											Already have a password?
											<a className="ms-2" href="login.html">
												Sign in
											</a>
										</P>
									</Form>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<ToastContainer />
		</Fragment>
	);
};

export default ResetPwd;
