import axios from "axios";
import React, {Fragment, useEffect, useState} from "react";
import {FaHandPaper} from "react-icons/fa";
import {Card, Col, Row, Container} from "reactstrap";
import {H4, Image, P} from "../../AbstractElements";
import ModalAlert from "../ModalAlert/Modal";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import "./NewsArticle.css";
import {Helmet} from "react-helmet";
import {HelmetProvider} from 'react-helmet-async';

const NewsArticle = () => {
    const [article, setArticle] = useState(null);
    const API_BASE_URL = window.appConfig.API_BASE_URL;
    const [pageLoaded, setPageLoaded] = useState(false);
    const [likedArticles, setLikedArticles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const newsArticleId = location.pathname.substring(
        location.pathname.lastIndexOf("/") + 1
    );
    const helmetContext = {};
    const [image, setImage] = useState(null)


    const getArticles = async () => {
        const res = await axios.get(
            `${API_BASE_URL}/api/NewsArticles/${newsArticleId}`
        );
        setArticle(res.data);
        setPageLoaded(true);
        return {article};
    };

    const getImage = async () => {
        const res = await axios.get(
            `${API_BASE_URL}/api/NewsArticles/FetchArticleThumbnail/${newsArticleId}`
        );
        console.log(res)
        setImage(res.data)
    };

    const formatDate = (apiDate) => {
        const date = new Date(apiDate);
        return date.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
    };

    useEffect(() => {
        getArticles();
        getImage();
    }, [newsArticleId]);

    return (
        <HelmetProvider context={helmetContext}>

            <Fragment>
                <Container fluid={true}>
                    <ModalAlert
                        showModal={showModal}
                        setShowModal={setShowModal}
                        postMessage={postMessage}
                    />
                    <div>
                        {article && (
                            <Card style={{height: "100%"}}>
                                <Helmet>
                                    <title>{article.title}</title>
                                    <meta name="description" content={article.summary} data-react-helmet="true" />
                                    <meta name="keywords" content={article.tags} data-react-helmet="true" />
                                    <meta property="og:type" content="webite" data-react-helmet="true"/>
                                    <meta
                                        property="og:image"
                                        content={
                                            API_BASE_URL +
                                            "/api/NewsArticles/FetchArticleThumbnail/" +
                                            article.newsArticleId
                                        }
                                        data-react-helmet="true"
                                    />
                                    <meta property="og:title" content={article.title} data-react-helmet="true" />
                                    <meta property="og:description" content={article.summary} data-react-helmet="true" />
                                    <meta property="og:url" content={article.url} data-react-helmet="true" />
                                    <meta name="google-adsense-account" content="ca-pub-7778800188419604" />
                                </Helmet>
                                {pageLoaded && (
                                    <Row>
                                        <Col sm="5" className="side-blog-con">
                                            <Image
                                                attrImage={{
                                                    className: "img-fluid sm-100- p-0 side-blog-images",
                                                    src: `${API_BASE_URL}/api/NewsArticles/FetchArticleThumbnail/${article.newsArticleId}`,
                                                    alt: "",
                                                }}
                                            />
                                        </Col>
                                        <Col sm="7" id="article-col">
                                            <div className="blog-details">
                                                <H4>
                                                    <a
                                                        rel="noopener noreferrer"
                                                        style={{color: "#00b4b4", cursor: "pointer", fontWeight: "600"}}
                                                        href={article.url}
                                                    >
                                                        {article.title}
                                                    </a>
                                                </H4>
                                                <div className="blog-bottom-content">
                                                    <ul className="blog-social">
                                                        <li>{`by: ${article.providerName}`}</li>
                                                    </ul>
                                                    <hr style={{color: "#e6edef"}}/>
                                                    <P attrPara={{className: "mt-0 article-summary"}}>
                                                        {article.summary}
                                                    </P>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </Card>
                        )}
                    </div>
                </Container>
            </Fragment>
        </HelmetProvider>
    );
};

export default NewsArticle;
