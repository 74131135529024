export default [
	"NA",
	"0 – 10 000",
	"10 001 – 15 000",
	"15 001 – 20 000",
	"20 001 – 25 000",
	"25 001 – 30 000",
	"30 000 – 40 000",
	"40 001 – 50 000",
	"50 001 – 60 000",
	"60 001 – 70 000",
	"70 000 – 80 000",
	"80 001 – 100 000",
	"100 000 +",
];
