import React, { Fragment, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, P } from "../../../AbstractElements";
import "./styles.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ForgetPwd = () => {
	const { token } = useParams();
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate(); // Correct hook name

	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent the form from reloading
		setLoading(true);

		try {
			const res = await axios.post(
				`${API_BASE_URL}/Account/forgot-password`,
				{
					email: email,
				},
				{ headers: { "Content-Type": "application/json" } }
			);
			if (res.status === 200) {
				setEmail("");
				toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
				navigate("/reset-password").replace(); // Correct navigation usage
			}
		} catch (err) {
			let errorMessage = "Error occurred while resetting password.";
			errorMessage = err?.response?.data?.message ?? errorMessage;
			toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
		} finally {
			setLoading(false);
		}
	};

	return (
		<Fragment>
			<section>
				<Container className="p-0" fluid={true}>
					<Row className="m-0">
						<Col className="p-0">
							<div className="login-card">
								<div className="login-main" id="forgot-password">
									<Form
										className="theme-form login-form"
										onSubmit={handleSubmit}
									>
										<H4 attrH4={{ className: "mb-3" }}>Reset Your Password</H4>
										<FormGroup>
											<Label>Enter Your Email Address</Label>
											<Row>
												<Col xl="12" sm="12">
													<Input
														className="form-control"
														type="text"
														placeholder="johndoe@gmail.com"
														value={email}
														onChange={(e) => setEmail(e.target.value)}
													/>
												</Col>
											</Row>
										</FormGroup>
										<FormGroup>
											<Btn
												attrBtn={{
													className: "btn-block",
													color: "primary",
													type: "submit",
												}}
											>
												Send
											</Btn>
										</FormGroup>
										<P>
											Already have a password?
											<a className="ms-2" href="login.html">
												Sign in
											</a>
										</P>
									</Form>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Fragment>
	);
};

export default ForgetPwd;
