import { Auth0Provider } from "@auth0/auth0-react";
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Signin from "../Auth/Signin";
import { auth0, firebase_app } from "../Config/Config";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import {
	authHeader,
	configureFakeBackend,
	handleResponse,
} from "../Services/fack.backend";
import { authRoutes } from "./AuthRoutes";
import PrivateRoute from "./PrivateRoute";

// setup fake backend
configureFakeBackend();
const Routers = () => {
	const [currentUser, setCurrentUser] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const jwt_token = localStorage.getItem("token");

	useEffect(() => {
		let abortController = new AbortController();
		const requestOptions = { method: "GET", headers: authHeader() };
		fetch("/users", requestOptions).then(handleResponse);
		firebase_app.auth().onAuthStateChanged(setCurrentUser);
		setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
		console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
		console.disableYellowBox = true;
		return () => {
			abortController.abort();
		};
	}, []);

	return (
		<Auth0Provider
			domain={auth0.domain}
			clientId={auth0.clientId}
			redirectUri={auth0.redirectUri}
		>
			<BrowserRouter basename={"/"}>
				<>
					<Suspense fallback={<Loader />}>
						<Routes>
							<Route exact path={"/"} element={<Navigate to={`/NewsFeed`} />} />
							<Route path={"/"} element={<PrivateRoute />}>
								{currentUser !== null || authenticated || jwt_token ? (
									<>
										<Route
											exact
											path={`${process.env.PUBLIC_URL}`}
											element={<Navigate to={`login`} />}
										/>
										<Route
											exact
											path={`/`}
											element={<Navigate to={`login`} />}
										/>
									</>
								) : (
									""
								)}
								<Route path={`/*`} element={<LayoutRoutes />} />
							</Route>
							{/* <Route path={`callback`} render={() => <Callback />} /> */}
							<Route exact path={`login`} element={<Signin />} />
							{authRoutes.map(({ path, Component }, i) => (
								<Route path={path} element={Component} key={i} />
							))}
						</Routes>
					</Suspense>
				</>
			</BrowserRouter>
		</Auth0Provider>
	);
};

export default Routers;
