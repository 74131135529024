import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import ArticleRedirect from "../Components/ArticleRedirect/ArticleRedirect";
import { loginProvider } from "../Config/Config";

const PrivateRoute = () => {
	const [currentUser, setCurrentUser] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const jwt_token = sessionStorage.getItem("token");
	const location = useLocation();
	const { newsArticleId } = useParams();

	useEffect(() => {
		loginProvider.onAuthStateChanged(() => setCurrentUser(true));
		setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
	}, []);

	if (location.pathname === "/NewsFeed") {
		return <Outlet />;
	}

	if (location.pathname.startsWith("/NewsArticle/")) {
		return <Outlet />;
	}

	if (location.pathname === "/Redirect") {
		return <ArticleRedirect />;
	}

	return jwt_token || authenticated ? <Outlet /> : <Navigate to={`/login`} />;
};

export default PrivateRoute;
