import React, { useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import { EmailAddress, ResetPassword } from "../../../Constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ForgotPassword = () => {
    const { token } = useParams();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Correct hook name

    const API_BASE_URL = window.appConfig.API_BASE_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await axios.post(
                `${API_BASE_URL}/Account/forgot-password`,
                {
                    email: email,
                },
                { headers: { "Content-Type": "application/json" } }
            );
            if (res.status === 200) {
                setEmail("");
                toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
                navigate("/reset-password"); // Correct navigation usage
            }
        } catch (err) {
            let errorMessage = "Error occurred while resetting password.";
            errorMessage = err?.response?.data?.message ?? errorMessage;
            toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Form className="theme-form" onSubmit={handleSubmit}>
                <ToastContainer />
                <H4>Forgot Password</H4>
                <P>{"Enter your email address to reset your password"}</P>
                <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                        className="form-control"
                        type="email"
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </FormGroup>
                <div className="form-group mb-0">
                    <Btn
                        attrBtn={{
                            color: "primary",
                            className: "btn-block",
                            disabled: loading ? loading : loading,
                        }}
                    >
                        {loading ? "LOADING..." : ResetPassword}
                    </Btn>
                </div>
            </Form>
        </div>
    );
};

export default ForgotPassword;