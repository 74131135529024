import axios from "axios";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import { BreadcrumbsNew, Btn } from "../../AbstractElements";
import "./styles.css";

const ContactUs = () => {
	const [message, setMessage] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const handleMessageChange = (event) => {
		setMessage(event.target.value);
	};

	async function postContactForm(e) {
		e.preventDefault();
		const email = sessionStorage.getItem("email");
		if (!message) return;

		try {
			let response = await axios({
				method: "post",
				url: `${API_BASE_URL}/ContactUs/AddContact`,
				data: {
					email: email,
					message: message,
				},
			});
			setMessage("");
			return directiontoaster("directionssuccessToast");
		} catch (error) {
			return directiontoaster("directionsdangerToast");
		}
	}

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Your message has been sent", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error sending your message", {
					position: toast.POSITION.TOP_CENTER,
				});
				break;
		}
	};

	return (
		<Fragment>
			<Container fluid={true}>
				<BreadcrumbsNew title="Contact Us" mainTitle="Contact Us" />
				<Form onSubmit={postContactForm}>
					<Row>
						<Col md="6" sm="12" id="card-con-1">
							<Card id="con-main-card">
								<CardBody>
									<h5 id="contact-header">Send us a message</h5>
									<textarea
										type="text"
										rows="10"
										className="form-control"
										required={true}
										value={message}
										id="form-notes"
										onChange={handleMessageChange}
									/>
									<Btn
										attrBtn={{
											color: "primary",
											type: "submit",
											id: "update-btn",
										}}
									>
										Submit Message
									</Btn>
								</CardBody>
							</Card>
						</Col>
						<Col md="6" sm="12" id="card-con-2">
							<Card id="con-main-card">
								<CardBody>
									<Row>
										<div className="media-body-contact">
											<div className="detail"></div>
											<div className="login-text-contact">
												<div>
													<br></br>
													<div>
														<b>Your feedback would be appreciated</b>
													</div>
													<br></br>
													<div>
														Should you be experiencing any problems with
														registering your details or applying for a position
														then please let us know.
													</div>
													<br></br>
													<div>
														SkilledUp is constantly looking at ways to provide
														our community with more features and value. We
														appreciate any input that can help us serve you
														better.
													</div>
												</div>
											</div>
										</div>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Form>
			</Container>
		</Fragment>
	);
};

export default ContactUs;
