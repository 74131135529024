import React, { useState } from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";

const ModalDelete = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [selectedValue, setSelectedValue] = useState([]);
	const candidateId = sessionStorage.getItem("candidateId");

	const deleteHandler = async () => {
		try {
			toggle();

			await props.onDelete();

			const newData = await axios.get(
				`${API_BASE_URL}/CandidatePosition/GetByCandidateId/${candidateId}`
			);

			const positionData = newData.data.map((item) => ({
				candidatePositionId: item.candidatePositionId,
				candidateId: item.candidateId,
				title: item.title,
				companyName: item.companyName,
				jobDescription: item.jobDescription,
				startDate: item.startDate,
				endDate: item.endDate,
				active: item.active,
				mainSkillId: selectedValue.skillsetId,
				mainSkillName: selectedValue.mainSkillName,
			}));

			props.updatePositionData(positionData);
			return directiontoaster("directionssuccessToast");
		} catch (error) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("Position deleted successfully", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error deleting this position", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
		}
	};

	return (
		<div>
			<i
				className="icon-hover"
				id="work-experience-candidate"
				title="Delete"
				onClick={toggle}
			>
				<FaRegTrashAlt />
			</i>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader>{props.deleteMessage}</ModalHeader>
				<ModalFooter>
					<Button id="cancel-mail" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={deleteHandler}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ModalDelete;
