import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { BreadcrumbsNew } from "../../../AbstractElements";
import ListCard from "./ListCard";

const ListView = () => {
	return (
		<Fragment>
			<Container fluid={true}>
				<BreadcrumbsNew title="Job Listings" mainTitle="Job Listings" />
				<Row>
					<ListCard colClass="col-xl-12 xl-100 box-col-12" />
				</Row>
			</Container>
		</Fragment>
	);
};

export default ListView;
