import React, { Fragment, useContext, useState } from "react";
import { AlignCenter } from "react-feather";
import { Image } from "../../AbstractElements";
import CheckContext from "../../_helper/Customizer";

const Leftbar = () => {
	const { mixLayout, toggleSidebar, toggleIcon } = useContext(CheckContext);
	const [toggle, setToggle] = useState(false);

	const openCloseSidebar = () => {
		setToggle(!toggle);
		toggleSidebar(toggle);
	};

	return (
		<Fragment>
			<div
				className="main-header-left"
				style={{
					borderBottom: "1px solid rgba(0, 180, 180, 0.2);",
				}}
			>
				{/* <div className="logo-wrapper">
					<Image
						attrImage={{
							className: "img-fluid",
							src: `/skilled-up-logo.svg`,
							alt: "SkilledUp",
						}}
					/>
				</div> */}
				<img
					src="/skilled-up-logo.svg"
					alt=""
					srcset=""
					style={{ width: "180px" }}
				/>
				<div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
					<AlignCenter className="status_toggle middle" id="sidebar-toggle" />
				</div>
			</div>
		</Fragment>
	);
};

export default Leftbar;
