import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container } from "reactstrap";
import {
	BreadcrumbsNew,
	Btn,
	H6,
	Image,
	P,
} from "../../../../AbstractElements";
import JobDescription from "./jobDescription";
import "./style.css";

const MainCard = () => {
	const [data, setDate] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [rating, setRating] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	let url = window.location.href;
	let jobId = url.substring(url.lastIndexOf(":") + 1);
	let candidateId = sessionStorage.getItem("candidateId");

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/Job/${jobId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
				params: { candidateId: candidateId },
			})
			.then((res) => {
				console.log(candidateId, jobId);
				setDate(res.data);
				console.log(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	async function userJob() {
		const email = sessionStorage.getItem("email");
		const candidateId = sessionStorage.getItem("candidateId");
		const authToken = sessionStorage.getItem("token");

		try {
			let response = await axios({
				method: "post",
				url: `${API_BASE_URL}/CandidateJobApply/AddCandidateJobApply`,
				data: {
					candidateId: candidateId,
					jobId: jobId,
				},
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
			let message =
				"Thank you for your application. Your profile has been submitted for review";
			toast.success(message, { position: toast.POSITION.TOP_CENTER });
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<Fragment>
			<Container fluid={true} id="jobCard">
				<BreadcrumbsNew title="Job Detail" mainTitle="Job Detail" />
				<div className="job-container">
					<Col xl="9" className="col-xl-12 xl-100 box-col-12" id="job-card">
						<Card id="job-card">
							<div className="job-search">
								<CardBody>
									<div className="media" id="job-detail-media">
										<Image
											attrImage={{
												className: "img-40 img-fluid m-r-20",
												src: `${require("../../../../assets/images/job-search/1.jpg")}`,
												alt: "",
											}}
										/>
										<div className="media-body">
											<H6 attrH6={{ className: "f-w-600" }}>
												<a href="#javascript">{data.jobTitle}</a>
											</H6>
											<P>
												{data.jobTitle}, {data.jobType} - {data.jobLocation} (
												{data.workplaceType}) | {data.salaryType} - R{" "}
												{data.salaryRange}
											</P>
											<div className="detail">
												{!data.hasApplied && (
													<Link to={`/Job/:${data.jobId}`}>
														<Btn
															onClick={userJob}
															attrBtn={{
																className:
																	"btn btn-sm job-apply-btn job-detail",
																color: "primary",
																onClick: userJob,
															}}
														>
															Submit Application
														</Btn>
													</Link>
												)}
												{data.hasApplied && (
													<Btn
														attrBtn={{
															className:
																"btn btn-sm job-apply-btn job-detail btn-applied",
															color: "danger",
														}}
													>
														Applied
													</Btn>
												)}
											</div>
										</div>
									</div>
									<JobDescription />
								</CardBody>
							</div>
						</Card>
					</Col>
				</div>
			</Container>
		</Fragment>
	);
};

export default MainCard;
