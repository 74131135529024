import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './style.css';

const Certificates = () => {
  const API_BASE_URL = window.appConfig.API_BASE_URL;
  const [fullName, setFullName] = useState('');
  const [description, setDescription] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(true);
  const { candidateId, candidateRewardId } = useParams();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const authToken = sessionStorage.getItem('token');

    axios
      .get(`${API_BASE_URL}/Candidate/${candidateId}/reward/${candidateRewardId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        const { fullName, description, result } = res.data[0];
        setFullName(fullName);
        setDescription(description);
        setResult(result);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });

    return () => {
      source.cancel();
    };
  }, [API_BASE_URL, candidateId, candidateRewardId]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="certificate-container">
      <div className="certificate-header">
        <h2 className="certificate-title">Certificate of Achievement</h2>
      </div>
      <div className="certificate-body">
        <h3 className="candidate-name">{fullName}</h3>
        <p className="certificate-description">{description}</p>
        <p className="certificate-result">
          Congratulations! The recipient, {fullName}, has successfully achieved {result}% in the given assessment.
        </p>
      </div>
    </div>
  );
};

export default Certificates;
