import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, TabContent, TabPane } from "reactstrap";
import { Image } from "../AbstractElements";
import AuthTab from "./Tabs/AuthTab";
import LoginTab from "./Tabs/LoginTab";
import "./Tabs/styles.css";

const Logins = () => {
	const [selected, setSelected] = useState("firebase");
	const google = window.google;
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [roleId, setRoleId] = useState(null);
	const history = useNavigate();

	useEffect(() => {
		sessionStorage.removeItem("token");
	}, []);

	const handleCallbackResponse = async (response) => {
		if (response) {
			console.log(response);
			const result = await axios.post(`${API_BASE_URL}/Account/GoogleLogin`, {
				provider: "Google",
				idToken: response.credential,
			});
			console.log(result);
			sessionStorage.setItem("candidateId", result.data.candidate.candidateId);
			sessionStorage.setItem("name", result.data.candidate.name);
			sessionStorage.setItem("surname", result.data.candidate.surname);
			sessionStorage.setItem("gender", result.data.candidate.gender);
			sessionStorage.setItem("jobTitle", result.data.candidate.currentJobTitle);
			sessionStorage.setItem("token", result.data.token);
			sessionStorage.setItem("email", result.data.candidate.email);

			setTimeout(() => {
				history(`/Edit-Profile`);
			}, 200);
		} else {
			console.error("Invalid response");
		}
	};

	useEffect(() => {
		if (
			typeof google !== "undefined" &&
			typeof google.accounts !== "undefined"
		) {
			google.accounts.id.initialize({
				client_id:
					"59946419762-jo5atd0990d8erhuinqtq47ogupb6iss.apps.googleusercontent.com",
				callback: handleCallbackResponse,
			});

			google.accounts.id.renderButton(document.getElementById("sign-in-div"), {
				theme: "outline",
				size: "small",
			});
		}
	}, []);

	const accessToken = sessionStorage.getItem("accessTokenGit");

	const getGitData = async () => {
		const result = await axios.post(
			`${API_BASE_URL}/Account/GitHubLogin?code=${accessToken}`
		);
		sessionStorage.setItem("candidateId", result.data.candidate.candidateId);
		sessionStorage.setItem("name", result.data.candidate.name);
		sessionStorage.setItem("surname", result.data.candidate.surname);
		sessionStorage.setItem("gender", result.data.candidate.gender);
		sessionStorage.setItem("jobTitle", result.data.candidate.currentJobTitle);
		sessionStorage.setItem("token", result.data.token);
		sessionStorage.setItem("email", result.data.candidate.email);

		setTimeout(() => {
			history(`/Edit-Profile`);
		}, 200);
	};

	useEffect(() => {
		if (accessToken) {
			getGitData();
		}
	}, [accessToken]);
	
	return (
		<Container fluid={true} className="p-0">
			<Row>
				<Col xs="12">
					<div className="login-card">
						<div className="login-main login-tab" id="login-new-1">
							<TabContent activeTab={selected} className="content-login">
								<TabPane
									className="fade show"
									tabId={selected === "firebase" ? "firebase" : "jwt"}
								>
									<LoginTab selected={selected} />
								</TabPane>
								<TabPane className="fade show" tabId="auth0">
									<AuthTab />
								</TabPane>
							</TabContent>
						</div>
						<div className="login-main login-tab" id="login-new-2">
							<TabContent activeTab={selected} className="content-login">
								<div className="media" id="login-media">
									<div className="login-top">
										<Image
											attrImage={{
												className: "img-fluid login-icon",
												src: `/skilled-up-logo.svg`,
												alt: "",
											}}
										/>
									</div>
									<div className="media-body">
										<div className="detail"></div>
										<div className="login-text" style={{ color: "#898989" }}>
											<br></br>
											<p>
												<b>Welcome back!</b>
											</p>
											<br></br>
											<p>
												Our space provides a platform for software developers to
												connect with other software developers and potentially
												find work opportunities.
											</p>
											<br></br>
											<p>
												We are working hard to continuously add new features and
												bring extra benefits to our users.
											</p>
											<br></br>
											<p>
												Please take the time to keep your information up to
												date.
											</p>
										</div>
										<div id="sign-in-div"></div>
									</div>
								</div>
							</TabContent>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Logins;
