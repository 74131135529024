import React, { useState, useEffect } from 'react'

export default (props = { startTime: new Date() }) => {
    const [startTime, setStartTime] = useState(props.startTime);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    function getTime() {
        const currentTime = new Date();
        const timeElapsedMs = currentTime.getTime() - startTime.getTime();
        let seconds = Math.floor(timeElapsedMs / 1000);
        let minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        setSeconds(seconds);
        setMinutes(minutes);
        setHours(hours);
    }

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>{hours}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</>
    );
}
