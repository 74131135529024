import axios from "axios";
import React, { Fragment, useEffect } from "react";
import { Linkedin } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Btn } from "../../../AbstractElements";

const LinkedInCus = () => {
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const history = useNavigate();
	const accessTokenLinkedIn = sessionStorage.getItem("accessTokenLinkedIn");
	const accessStateLinkedIn = sessionStorage.getItem("accessStateLinkedIn");

	const getLinkedInData = async () => {
		const result = await axios.post(
			`${API_BASE_URL}/Account/LinkedInLogin?code=${accessTokenLinkedIn}&state=${accessStateLinkedIn}`
		);
		sessionStorage.setItem("candidateId", result.data.candidate.candidateId);
		sessionStorage.setItem("name", result.data.candidate.name);
		sessionStorage.setItem("surname", result.data.candidate.surname);
		sessionStorage.setItem("gender", result.data.candidate.gender);
		sessionStorage.setItem("jobTitle", result.data.candidate.currentJobTitle);
		sessionStorage.setItem("token", result.data.token);
		sessionStorage.setItem("email", result.data.candidate.email);

		setTimeout(() => {
			history(`/Edit-Profile`);
		}, 0);
	};

	useEffect(() => {
		if (accessTokenLinkedIn) {
			getLinkedInData();
		}
	}, [accessTokenLinkedIn]);

	return (
		<Fragment>
			<a
				href={
					"https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77l1svdsgxg7c8&redirect_uri=https://skilledup.co.za/login&state=987654321&scope=openid,profile,w_member_social,email"
				}
			>
				<Btn
					attrBtn={{
						color: "dark",
						onClick: () => {},
						id: "linkedin-login-btn"
					}}
				>
					<Linkedin />
				</Btn>
			</a>
		</Fragment>
	);
};

export default LinkedInCus;
