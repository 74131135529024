import React, { Fragment } from "react";
import { GitHub } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Btn } from "../../../AbstractElements";

const GithubCus = () => {
	const history = useNavigate();

	const githubAuth = async () => {};

	return (
		<Fragment>
			<a href="https://github.com/login/oauth/authorize?client_id=54e4a8d7bbb635630f1b&redirect_uri=https://skilledup.co.za/login">
				<Btn attrBtn={{ color: "dark", onClick: githubAuth, id: "git-login-btn" }}>
					<GitHub />
				</Btn>
			</a>
		</Fragment>
	);
};

export default GithubCus;
