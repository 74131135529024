import React, { useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import { NewPassword, ConfirmPassword, ResetPassword } from "../../../Constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const ResetPasswords = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	const url = window.location.href;
	const index = url.lastIndexOf("=");
	const token = url.slice(index + 1);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		// Rest password
		try {
			const res = await axios.post(
				`${API_BASE_URL}/Account/reset-password`,
				{
					Email: email,
					NewPassword: password,
					ConfirmPassword: confirmPassword,
					ResetToken: token,
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			if (res.status === 200) {
				setPassword("");
				setConfirmPassword("");
				toast.success(res.data, { position: toast.POSITION.TOP_CENTER });
				navigate("/login");
			}
		} catch (err) {
			let errorMessage = "Error occurred while resetting password.";
			errorMessage = err?.response?.data?.message ?? errorMessage;
			toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<Form className="theme-form" onSubmit={handleSubmit}>
				<ToastContainer />
				<H4>Reset Password</H4>
				<P>
					{
						"Enter your email, new password, confirm it, and provide the reset token"
					}
				</P>
				<FormGroup>
					<Label className="col-form-label">Email</Label>
					<Input
						className="form-control"
						type="email"
						required={true}
						onChange={(e) => setEmail(e.target.value)}
						value={email}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="col-form-label">{NewPassword}</Label>
					<Input
						className="form-control"
						type="password"
						required={true}
						minLength={8}
						onChange={(e) => setPassword(e.target.value)}
						value={password}
					/>
				</FormGroup>
				<FormGroup>
					<Label className="col-form-label">{ConfirmPassword}</Label>
					<Input
						className="form-control"
						type="password"
						required={true}
						minLength={8}
						onChange={(e) => setConfirmPassword(e.target.value)}
						value={confirmPassword}
					/>
				</FormGroup>
				<div className="form-group mb-0">
					<Btn
						attrBtn={{
							color: "primary",
							className: "btn-block",
							disabled: loading ? loading : loading,
						}}
					>
						{loading ? "LOADING..." : ResetPassword}
					</Btn>
				</div>
			</Form>
		</div>
	);
};

export default ResetPasswords;
