import React, { useState, useRef } from "react";
import "../JobSearch/JobCardView/Cards/styles.css";
import axios from "axios";
import { TopCenter, directiontoaster } from "../../Constant";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import "./Modal.css";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	FormGroup,
	Input,
	Col,
	Row,
} from "reactstrap";
import DatePicker from "react-datepicker";

const ModalLink = (props) => {
	const email = props.candidateEmail;
	const [modal, setModal] = useState(false);
	const [subject, setSubject] = useState("");
	const [body, setBody] = useState("");
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const candidateId = sessionStorage.getItem("candidateId");
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [title, setTitle] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [jobDescription, setJobDescription] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [jobNotes, setJobNotes] = useState("");
	const [isStartCalendar, setIsStartCalendar] = useState(false);
	const [isEndCalendar, setIsEndCalendar] = useState(false);
	const calendarRefStart = useRef(null);
	const calendarRef = useRef(null);
	const today = new Date();
	const currentDate = today.toISOString().split("T")[0];
	const [_inputValue, setValue] = useState([]);
	const [selectedValue, setSelectedValue] = useState([]);

	const toggle = () => {
		if (modal) {
			setModal(false);
			setSubject("");
			setBody("");
			setTitle("");
			setCompanyName("");
			setJobDescription("");
			setStartDate(null);
			setEndDate(null);
			setJobNotes("");
			setIsStartCalendar(false);
			setIsEndCalendar(false);
			setValue([]);
			setSelectedValue(null);
			setSelectedSkills([]);
		} else {
			setModal(true);
		}
	};

	const postMessage = async () => {
		setModal(false);
		setSubject("");
		setBody("");
		setTitle("");
		setCompanyName("");
		setJobDescription("");
		setStartDate(null);
		setEndDate(null);
		setJobNotes("");
		setIsStartCalendar(false);
		setIsEndCalendar(false);
		setValue([]);
		setSelectedValue(null);
		setSelectedSkills([]);

		try {
			let res = await axios.post(
				`${API_BASE_URL}/CandidatePosition/AddCandidatePosition`,
				{
					candidateId: candidateId,
					title: title,
					companyName: companyName,
					jobDescription: jobDescription,
					startDate: startDate,
					endDate: endDate,
					active: true,
					MainSkillId: selectedValue.skillsetId,
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			console.log(res.data);

			const skillSetRequestBody = {
				CandidatePositionId: res.data,
				skills: selectedSkills.map((e) => e.skillsetId),
			};

			await axios.post(
				`${API_BASE_URL}/CandidatePositionSkillset/AddCandidatePositionSkillset`,
				skillSetRequestBody
			);

			const newData = await axios.get(
				`${API_BASE_URL}/CandidatePosition/GetByCandidateId/${candidateId}`
			);

			const positionData = newData.data.map((item) => ({
				candidatePositionId: item.candidatePositionId,
				candidateId: item.candidateId,
				title: item.title,
				companyName: item.companyName,
				jobDescription: item.jobDescription,
				startDate: item.startDate,
				endDate: item.endDate,
				active: item.active,
				mainSkillId: selectedValue.skillsetId,
				mainSkillName: item.mainSkillName,
			}));

			props.updatePositionData(positionData);
			return directiontoaster("directionssuccessToast");
		} catch (err) {
			return directiontoaster("directionsdangerToast");
		}
	};

	const handleDateChangeStart = (date) => {
		setStartDate(date);
	};

	const handleDateChangeEnd = (date) => {
		setEndDate(date);
	};

	const directiontoaster = (toastname) => {
		switch (toastname) {
			case "directionssuccessToast":
				toast.success("The position was successfully added", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
			case "directionsdangerToast":
				toast.error("There was an error adding the position", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
				});
				break;
		}
	};

	const handleChange = (values) => {
		values = Array.isArray(values) ? values : [...values];
		setSelectedSkills(values);
	};

	const promiseOptions = async (_inputValue) => {
		const res = await axios.get(`${API_BASE_URL}/Skillset`);
		const allSkills = res.data;

		const filteredSkills = allSkills.filter((skill) => {
			const skillIds = selectedSkills.map(
				(selectedSkill) => selectedSkill.skillsetId
			);
			return !skillIds.includes(skill.skillsetId);
		});

		return filteredSkills;
	};

	const handleInputChange = (value) => {
		setValue(value);
	};

	const getSavedskills = async (_inputValue) => {
		const res = await axios.get(
			`${API_BASE_URL}/CandidateSkillset/${candidateId}`
		);
		return await res.data;
	};

	const handleInputChangeMain = (inputValue) => {
		setValue(inputValue);
	};

	const fetchData = async () => {
		try {
			const response = await axios.get(`${API_BASE_URL}/Skillset`);
			const data = response.data;
			return data;
		} catch (error) {
			console.error(error);
		}
	};

	const handleChangeMain = (selectedOption) => {
		setSelectedValue(selectedOption);
	};

	return (
		<div>
			<Button color="primary" onClick={toggle} style={{ width: "200px" }}>
				Add Job Position
			</Button>
			<Modal size="lg" isOpen={modal} toggle={toggle}>
				<ModalHeader>Add Work Experience</ModalHeader>
				<ModalBody style={{ height: "75vh" }}>
					<FormGroup>
						<Row>
							<Col md="6">
								<Label className="form-label">
									Title <sup className="mandatory">*</sup>
								</Label>
								<Input
									type="email"
									name=""
									id="exampleEmail"
									value={email}
									required
									className="form-control"
									onChange={(e) => setTitle(e.target.value)}
								/>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Row>
							<Col md="6">
								<Label className="form-label">
									Company Name <sup className="mandatory">*</sup>
								</Label>
								<Input
									type="email"
									name=""
									id="exampleEmail"
									value={email}
									required
									className="form-control"
									onChange={(e) => setCompanyName(e.target.value)}
								/>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Row>
							<Col md="6">
								<Label className="form-label">
									Start Date <sup className="mandatory">*</sup>
								</Label>
								<DatePicker
									className="form-control"
									dateFormat="MM/yyyy"
									showMonthYearPicker
									onChange={handleDateChangeStart}
									value={startDate}
									selected={startDate}
								/>
							</Col>
							<Col md="6">
								<Label className="form-label">
									End Date <sup className="mandatory">*</sup>
								</Label>
								<DatePicker
									className="form-control"
									dateFormat="MM/yyyy"
									showMonthYearPicker
									onChange={handleDateChangeEnd}
									value={endDate}
									selected={endDate}
								/>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Row>
							<Col md="12">
								<Label className="form-label">
									Job Description <sup className="mandatory">*</sup>
								</Label>
								<textarea
									type="text"
									rows="8"
									className="form-control"
									onChange={(e) => setJobDescription(e.target.value)}
								/>
							</Col>
						</Row>
					</FormGroup>
					<FormGroup>
						<Label className="form-label">
							Skills <sup className="mandatory">*</sup>
						</Label>
						<AsyncSelect
							cacheOptions
							defaultOptions
							required
							selectOptions
							getOptionLabel={(e) => e.name}
							getOptionValue={(e) => e.skillsetId}
							value={selectedSkills}
							loadOptions={promiseOptions}
							onInputChange={handleInputChange}
							onChange={handleChange}
							isMulti={true}
							className="form-control"
							id="form-skill"
						/>
					</FormGroup>
					<FormGroup>
						<Label className="form-label">
							Main Skill <sup className="mandatory">*</sup>
						</Label>
						<AsyncSelect
							cacheOptions
							defaultOptions
							value={selectedValue}
							getOptionLabel={(e) => e.name}
							getOptionValue={(e) => e.skillsetId}
							loadOptions={fetchData}
							onInputChange={handleInputChangeMain}
							onChange={handleChangeMain}
							isMulti={false}
							className="form-control"
							id="form-skill"
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button id="cancel-mail" onClick={toggle}>
						Cancel
					</Button>
					<Button color="primary" onClick={postMessage}>
						Add Position
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ModalLink;
