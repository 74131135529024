import ForgotPassword from "../Auth/Tabs/LoginTab/ForgotPassword";
import ResetPassword from "../Auth/Tabs/LoginTab/ResetPassword";
import EditProfile from "../Components/Application/Users/EditProfile";
import ArticleRedirect from "../Components/ArticleRedirect/ArticleRedirect";
import BlogDetail from "../Components/Blog/BlogDetail";
import Bulletin from "../Components/Bulletin/Bulletin";
import Certificates from "../Components/Certificates/Certificates";
import ContactUs from "../Components/ContactUs/ContactUs";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import BulletinDetails from "../Components/JobSearch/JobCardView/BulletinDetail";
import MainCard from "../Components/JobSearch/JobDetail/MainCard";
import ListView from "../Components/JobSearch/ListView";
import NewsArticle from "../Components/NewsArticle/NewsArticle";
import ForgetPwd from "../Components/Pages/Auth/ForgetPwd";
import SkillTest from "../Components/SkillTest/SkillTest";
import SkillTestConfirmation from "../Components/SkillTestConfirmation/SkillTestConfirmation";

export const routes = [
	{
		path: `Bulletin`,
		Component: <Bulletin />,
	},
	{
		path: `Job/:id`,
		Component: <MainCard />,
	},
	{
		path: `Edit-Profile`,
		Component: <EditProfile />,
	},
	{
		path: `Contact-Us`,
		Component: <ContactUs />,
	},
	{
		path: `View-Jobs`,
		Component: <ListView />,
	},
	{
		path: `Bulletin/:id`,
		Component: <BulletinDetails />,
	},
	{
		path: `SkillTestConfirmation:id`,
		Component: <SkillTestConfirmation />,
	},
	{
		path: `Skill-Test:id`,
		Component: <SkillTest />,
	},
	{
		path: `Skills-List`,
		Component: <ImageWithDesc />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Candidate/:candidateId/reward/:candidateRewardId`,
		Component: <Certificates />,
	},
	{
		path: `${process.env.PUBLIC_URL}/Account/forgot-password`,
		Component: <ForgotPassword />,
	},
	{
		path: `reset-password`,
		Component: <ResetPassword />,
	},
	{
		path: `test-password`,
		Component: <ForgetPwd />,
	},
	{
		path: `NewsFeed`,
		Component: <BlogDetail />,
	},
	{
		path: `/Redirect`,
		Component: <ArticleRedirect />,
	},
	{
		path: `/NewsArticle/:newsArticleId`,
		Component: <NewsArticle />,
	},
];
