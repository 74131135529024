import React, {useEffect} from "react";
import Routers from "./Route";
import ProductProvider from "./_helper/Ecommerce/Product/ProductProvider";
import CartProvider from "./_helper/Ecommerce/Cart/CardProvider";
import FilterProvider from "./_helper/Ecommerce/Filter/FilterProvider";
import WishListProvider from "./_helper/Ecommerce/Wishlist/WishlistProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";

const App = () => {
    const API_BASE_URL = window.appConfig.API_BASE_URL;

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const codeParam = urlParams.get("code");
        const stateParam = urlParams.get("state");

        if (stateParam === "987654321") {
            sessionStorage.setItem("accessTokenLinkedIn", codeParam);
            sessionStorage.setItem("accessStateLinkedIn", stateParam);

        } else {
            sessionStorage.setItem("accessTokenGit", codeParam);
        }

        if (codeParam && localStorage.getItem("accessToken") === null) {
            async function getAccessToken() {
                await fetch(API_BASE_URL + "/Account/GitHubLogin?code=" + codeParam, {
                    method: "GET"
                }).then((response) => {
                    return response.json();
                }).then((data) => {
                })
            }
            getAccessToken();
        }
    }, []);
    
    return (
        <div className="App">
            <CustomizerProvider>
                <WishListProvider>
                    <FilterProvider>
                        <CartProvider>
                            <ProductProvider>
                                <AnimationThemeProvider>
                                    <Routers/>
                                </AnimationThemeProvider>
                            </ProductProvider>
                        </CartProvider>
                    </FilterProvider>
                </WishListProvider>
            </CustomizerProvider>
        </div>
    );
};

export default App;
