import axios from "axios";
import { decode } from "html-entities";
import React, { Fragment, useEffect, useState } from "react";
import { FaHandPaper } from "react-icons/fa";
import { Card, Col } from "reactstrap";
import { H6, Image, P } from "../../../AbstractElements";
import ModalAlert from "../../ModalAlert/Modal";
import "./styles.css";

const Perspiciatis = () => {
	const [secondArticle, setSecondArticle] = useState(null);
	const [thirdArticle, setThirdArticle] = useState(null);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [pageLoaded, setPageLoaded] = useState(false);
	const candidateId = sessionStorage.getItem("candidateId");
	const [likedArticles, setLikedArticles] = useState([]);
	const [showModal, setShowModal] = useState(false);

	const getArticles = async () => {
		const res = await axios.get(
			`${API_BASE_URL}/api/NewsArticles/GetAllNewsArticles`
		);
		const [, secondArticle, thirdArticle] = res.data;
		setPageLoaded(true);
		return { secondArticle, thirdArticle };
	};

	const formatDate = (apiDate) => {
		const date = new Date(apiDate);
		return date.toLocaleDateString("en-GB", {
			day: "numeric",
			month: "short",
			year: "numeric",
		});
	};

	useEffect(() => {
		const fetchArticles = async () => {
			const { secondArticle, thirdArticle } = await getArticles();
			setSecondArticle(secondArticle);
			setThirdArticle(thirdArticle);
		};
		fetchArticles();
	}, [likedArticles]);

	useEffect(() => {
		const fetchLikedArticles = async () => {
			try {
				const res = await axios.get(
					`${API_BASE_URL}/NewsArticleLike/GetLikedArticles?candidateId=${candidateId}`
				);
				const likedArticleIds = res.data.map(
					(article) => article.newsArticleId
				);
				setLikedArticles(likedArticleIds);
			} catch (error) {
				console.error("Error fetching liked articles:", error);
			}
		};
		fetchLikedArticles();
	}, [candidateId]);

	const likeArticle = async (newsArticleId) => {
		try {
			if (!candidateId) {
				setShowModal(true);
				return;
			}

			const isLiked = likedArticles.includes(newsArticleId);

			if (isLiked) {
				await axios.post(
					`${API_BASE_URL}/NewsArticleLike/LikeArticle?candidateId=${candidateId}&newsArticleId=${newsArticleId}`
				);

				setLikedArticles((prevLikedArticles) =>
					prevLikedArticles.filter((articleId) => articleId !== newsArticleId)
				);
			} else {
				await axios.post(
					`${API_BASE_URL}/NewsArticleLike/LikeArticle?candidateId=${candidateId}&newsArticleId=${newsArticleId}`
				);

				setLikedArticles((prevLikedArticles) => [
					...prevLikedArticles,
					newsArticleId,
				]);
			}
		} catch (error) {
			console.error("Error liking/unliking article:", error);
		}
	};

	const incrementVisitCountSecond = async (
		newsArticleId,
		redirectUrl,
		articleName
	) => {
		try {
			const url = `/Redirect`;
			sessionStorage.setItem("redirectUrl", `${redirectUrl}`);
			sessionStorage.setItem("redirectUrlName", `${articleName}`);
			sessionStorage.setItem("newsArticleId", `${newsArticleId}`);

			window.open(url, "_blank");

			const correctedCandidateId = candidateId === null ? 1410 : candidateId;

			await axios.post(
				`${API_BASE_URL}/api/NewsArticleVisits/ArticleVisits?candidateId=${correctedCandidateId}&newsArticleId=${newsArticleId}`
			);

			setSecondArticle((prevArticle) => {
				return {
					...prevArticle,
					visitCount: prevArticle.visitCount + 1,
				};
			});
		} catch (error) {
			console.error("Error incrementing visit count:", error);
		}
	};

	const incrementVisitCountThird = async (
		newsArticleId,
		redirectUrl,
		articleName
	) => {
		try {
			const url = `/Redirect`;
			sessionStorage.setItem("redirectUrl", `${redirectUrl}`);
			sessionStorage.setItem("redirectUrlName", `${articleName}`);
			sessionStorage.setItem("newsArticleId", `${newsArticleId}`);

			window.open(url, "_blank");

			const correctedCandidateId = candidateId === null ? 1410 : candidateId;

			await axios.post(
				`${API_BASE_URL}/api/NewsArticleVisits/ArticleVisits?candidateId=${correctedCandidateId}&newsArticleId=${newsArticleId}`
			);

			setThirdArticle((prevArticle) => {
				return {
					...prevArticle,
					visitCount: prevArticle.visitCount + 1,
				};
			});
		} catch (error) {
			console.error("Error incrementing visit count:", error);
		}
	};

	const cleanUpSummary = (summary) => {
		const decodedSummary = decode(summary);
		const textOnly = decodedSummary.replace(/<[^>]+>/g, "");
		const cleanedSummary = textOnly.replace(/[^\w\s\d]/g, "");
		const wordsArray = cleanedSummary.trim().split(/\s+/);
		const first15Words = wordsArray.slice(0, 15).join(" ");
		return first15Words;
	};

	return (
		<Fragment>
			<ModalAlert
				showModal={showModal}
				setShowModal={setShowModal}
				postMessage={postMessage}
			/>
			<div style={{ height: "47.8%" }}>
				{secondArticle && (
					<Card style={{ height: "100%" }}>
						{!pageLoaded && (
							<div className="loader-box txt-primary">
								<div className="loader-7 txt-primary"></div>
							</div>
						)}
						{pageLoaded && (
							<div className="blog-box blog-list row side-blog-con">
								<Col sm="5" className="side-blog-con">
									<Image
										attrImage={{
											className: "img-fluid sm-100- p-0 side-blog-images",
											src: `${API_BASE_URL}/api/NewsArticles/FetchArticleThumbnail/${secondArticle.newsArticleId}`,
											alt: "",
										}}
									/>
								</Col>
								<Col sm="7">
									<div className="blog-details">
										<div className="blog-date digits">
											<div className="blog-date digits">
												<span>
													{new Date(secondArticle.createDate).getDate()}
												</span>
												{formatDate(secondArticle.createDate).slice(2)}
											</div>
										</div>

										<H6>
											<a
												onClick={() =>
													incrementVisitCountSecond(
														secondArticle.newsArticleId,
														secondArticle.url,
														secondArticle.title
													)
												}
												rel="noopener noreferrer"
												style={{ color: "#98a6ad", cursor: "pointer" }}
											>
												{secondArticle.title}
											</a>
										</H6>
										<div className="blog-bottom-content">
											<ul className="blog-social">
												<li>{`by: ${secondArticle.providerName}`}</li>
												<li attrli={{ className: "digits blog-bottom-li" }}>
													<p style={{ color: "#999" }}>
														<i
															className={
																likedArticles.includes(
																	secondArticle.newsArticleId
																)
																	? "icofont icofont-thumbs-up liked"
																	: "icofont icofont-thumbs-up"
															}
															onClick={() =>
																likeArticle(secondArticle.newsArticleId)
															}
														></i>
														{secondArticle.likeCount === 1
															? `${secondArticle.likeCount} Like`
															: `${secondArticle.likeCount} Likes`}
													</p>
												</li>
												<li className="digits blog-bottom-li">
													<p style={{ color: "#999" }}>
														<FaHandPaper style={{ marginRight: "5px" }} />
														{secondArticle.visitCount === 1
															? `${secondArticle.visitCount} Hit`
															: `${secondArticle.visitCount} Hits`}
													</p>
												</li>
											</ul>
											<hr style={{ color: "#e6edef" }} />
											<P attrPara={{ className: "mt-0" }}>
												{cleanUpSummary(secondArticle.summary)}...
											</P>
										</div>
									</div>
								</Col>
							</div>
						)}
					</Card>
				)}
				{thirdArticle && (
					<Card style={{ height: "100%" }}>
						{!pageLoaded && (
							<div className="loader-box txt-primary">
								<div className="loader-7 txt-primary"></div>
							</div>
						)}
						{pageLoaded && (
							<div className="blog-box blog-list row side-blog-con">
								<Col sm="5 side-blog-con">
									<Image
										attrImage={{
											className: "img-fluid sm-100-w p-0 side-blog-images",
											src: `${API_BASE_URL}/api/NewsArticles/FetchArticleThumbnail/${thirdArticle.newsArticleId}`,
											alt: "",
										}}
									/>
								</Col>
								<Col sm="7">
									<div className="blog-details">
										<div className="blog-date digits">
											<span>{new Date(thirdArticle.createDate).getDate()}</span>{" "}
											{formatDate(thirdArticle.createDate).slice(2)}
										</div>
										<H6>
											<a
												onClick={() =>
													incrementVisitCountThird(
														thirdArticle.newsArticleId,
														thirdArticle.url,
														thirdArticle.title
													)
												}
												rel="noopener noreferrer"
												style={{ color: "#98a6ad", cursor: "pointer" }}
											>
												{thirdArticle.title}
											</a>
										</H6>
										<div className="blog-bottom-content">
											<ul className="blog-social">
												<li>{`by: ${thirdArticle.providerName}`}</li>
												<li attrli={{ className: "digits blog-bottom-li" }}>
													<p style={{ color: "#999" }}>
														<i
															className={
																likedArticles.includes(
																	thirdArticle.newsArticleId
																)
																	? "icofont icofont-thumbs-up liked"
																	: "icofont icofont-thumbs-up"
															}
															onClick={() =>
																likeArticle(thirdArticle.newsArticleId)
															}
														></i>
														{thirdArticle.likeCount === 1
															? `${thirdArticle.likeCount} Like`
															: `${thirdArticle.likeCount} Likes`}
													</p>
												</li>
												<li className="digits blog-bottom-li">
													<p style={{ color: "#999" }}>
														<FaHandPaper style={{ marginRight: "5px" }} />
														{thirdArticle.visitCount === 1
															? `${thirdArticle.visitCount} Hit`
															: `${thirdArticle.visitCount} Hits`}
													</p>
												</li>
											</ul>
											<hr style={{ color: "#e6edef" }} />
											<P attrPara={{ className: "mt-0" }}>
												{cleanUpSummary(thirdArticle.summary)}...
											</P>
										</div>
									</div>
								</Col>
							</div>
						)}
					</Card>
				)}
			</div>
		</Fragment>
	);
};

export default Perspiciatis;
