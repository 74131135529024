import React, { Fragment, useState, useContext, useCallback } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import ListOfImageDesc from "./ImageGallery/ListOfImgDesc";

const ImageWithDesc = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Assessments" parent="Assessments" />
			<Container fluid={true}>
				<Row>
					<ListOfImageDesc />
				</Row>
			</Container>
		</Fragment>
	);
};

export default ImageWithDesc;
