import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { FaHandPaper } from "react-icons/fa";
import { Card, Col } from "reactstrap";
import { H4, Image, LI, P, UL } from "../../../AbstractElements";
import ModalAlert from "../../ModalAlert/Modal";
import "./styles.css";

const BlogBox = () => {
	const [article, setArticle] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [pageLoaded, setPageLoaded] = useState(false);
	const candidateId = sessionStorage.getItem("candidateId");
	const [likedArticles, setLikedArticles] = useState([]);
	const [showModal, setShowModal] = useState(false);

	const getArticle = async () => {
		try {
			const res = await axios.get(
				`${API_BASE_URL}/api/NewsArticles/GetAllNewsArticles`
			);
			if (res.data && res.data && res.data.length > 0) {
				const [firstArticle] = res.data;
				setPageLoaded(true);
				return firstArticle;
			} else {
				return null;
			}
		} catch (error) {
			console.error("Error fetching articles:", error);
			return null;
		}
	};

	const formatDate = (apiDate) => {
		const date = new Date(apiDate);
		return date.toLocaleDateString("en-GB", {
			day: "numeric",
			month: "short",
			year: "numeric",
		});
	};

	useEffect(() => {
		const fetchLikedArticles = async () => {
			try {
				const res = await axios.get(
					`${API_BASE_URL}/NewsArticleLike/GetLikedArticles?candidateId=${candidateId}`
				);
				const likedArticleIds = res.data.map(
					(article) => article.newsArticleId
				);
				setLikedArticles(likedArticleIds);
			} catch (error) {
				console.error("Error fetching liked articles:", error);
			}
		};
		fetchLikedArticles();
	}, [candidateId]);

	useEffect(() => {
		const fetchLatestArticle = async () => {
			const firstArticle = await getArticle();
			if (firstArticle) {
				setArticle(firstArticle);
			}
		};

		fetchLatestArticle();
	}, [likedArticles]);

	const likeArticle = async (newsArticleId) => {
		try {
			if (!candidateId) {
				setShowModal(true);
				return;
			}

			const isLiked = likedArticles.includes(newsArticleId);

			if (isLiked) {
				await axios.post(
					`${API_BASE_URL}/NewsArticleLike/LikeArticle?candidateId=${candidateId}&newsArticleId=${newsArticleId}`
				);

				setLikedArticles((prevLikedArticles) =>
					prevLikedArticles.filter((articleId) => articleId !== newsArticleId)
				);
			} else {
				await axios.post(
					`${API_BASE_URL}/NewsArticleLike/LikeArticle?candidateId=${candidateId}&newsArticleId=${newsArticleId}`
				);

				setLikedArticles((prevLikedArticles) => [
					...prevLikedArticles,
					newsArticleId,
				]);
			}
		} catch (error) {
			console.error("Error liking/unliking article:", error);
		}
	};

	const incrementVisitCount = async (
		newsArticleId,
		redirectUrl,
		articleName
	) => {
		try {
			const url = `/Redirect`;
			sessionStorage.setItem("redirectUrl", `${redirectUrl}`);
			sessionStorage.setItem("redirectUrlName", `${articleName}`);
			sessionStorage.setItem("newsArticleId", `${newsArticleId}`);

			window.open(url, "_blank");

			const correctedCandidateId = candidateId === null ? 1410 : candidateId;

			await axios.post(
				`${API_BASE_URL}/api/NewsArticleVisits/ArticleVisits?candidateId=${correctedCandidateId}&newsArticleId=${newsArticleId}`
			);

			setArticle((prevArticle) => {
				return {
					...prevArticle,
					visitCount: prevArticle.visitCount + 1,
				};
			});
		} catch (error) {
			console.error("Error incrementing visit count:", error);
		}
	};

	return (
		<Fragment>
			<ModalAlert
				showModal={showModal}
				setShowModal={setShowModal}
				postMessage={postMessage}
			/>
			<Col xl="6" className="xl-100 box-col-12">
				<Card style={{ height: "100%" }}>
					{!pageLoaded && (
						<div className="loader-box txt-primary">
							<div className="loader-7 txt-primary"></div>
						</div>
					)}
					{pageLoaded && (
						<div className="blog-box blog-shadow" style={{ height: "100%" }}>
							<a
								rel="noopener noreferrer"
								style={{ cursor: "pointer" }}
								onClick={() =>
									incrementVisitCount(
										article.newsArticleId,
										article.url,
										article.title
									)
								}
							>
								<Image
									attrImage={{
										className: "img-fluid blog-main-image",
										src: `${API_BASE_URL}/api/NewsArticles/FetchArticleThumbnail/${article.newsArticleId}`,
										alt: "",
									}}
								/>
							</a>
							<div className="blog-details">
								<P attrPara={{ className: "digits" }}>
									{formatDate(article.createDate)}
								</P>
								<H4>
									<a
										rel="noopener noreferrer"
										style={{ color: "#fff", cursor: "pointer" }}
										onClick={() =>
											incrementVisitCount(
												article.newsArticleId,
												article.url,
												article.title
											)
										}
									>
										{article.title}
									</a>
								</H4>
								<UL attrUL={{ className: "blog-social flex-row" }}>
									<LI>{article.providerName}</LI>
									<li attrli={{ className: "digits blog-bottom-li" }}>
										<p style={{ color: "#999" }}>
											<i
												className={
													likedArticles.includes(article.newsArticleId)
														? "icofont icofont-thumbs-up liked"
														: "icofont icofont-thumbs-up"
												}
												onClick={() => likeArticle(article.newsArticleId)}
											></i>
											{article.likeCount === 1
												? `${article.likeCount} Like`
												: `${article.likeCount} Likes`}
										</p>
									</li>
									<li attrli={{ className: "digits blog-bottom-li" }}>
										<p style={{ color: "#999" }}>
											<FaHandPaper style={{ marginRight: "5px" }} />
											{article.visitCount} Hits
										</p>
									</li>
								</UL>
							</div>
						</div>
					)}
				</Card>
			</Col>
		</Fragment>
	);
};

export default BlogBox;
