import {
	BookOpen,
	Briefcase,
	Mail,
	MessageCircle,
	Terminal,
	User,
} from "react-feather";

export const MENUITEMS = [
	{
		menucontent: "Dashboards,Widgets",
		Items: [
			{
				path: `/Edit-Profile`,
				icon: User,
				title: "My Profile",
				type: "link",
			},
			{
				path: `/NewsFeed`,
				icon: BookOpen,
				title: "Developer News",
				type: "link",
			},
			{
				path: `/Skills-List`,
				icon: Terminal,
				title: "Assessments",
				type: "link",
			},
			{
				path: `/View-Jobs`,
				icon: Briefcase,
				title: "Job Listings",
				type: "link",
			},
			{
				path: `/Bulletin`,
				icon: MessageCircle,
				title: "Notifications",
				type: "link",
			},
			{
				path: `/Contact-Us`,
				icon: Mail,
				title: "Contact Us",
				type: "link",
			},
		],
	},
];
