import React, {Fragment, useEffect} from "react";
import {Col, Container, Row} from "reactstrap";
import {Breadcrumbs} from "../../../AbstractElements";
import BlogBox from "./BlogBox";
import Gallery from "./Gallery";
import Perspiciatis from "./Perspiciatis";
import axios from "axios";

const BlogDetail = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Developer News" parent="News"/>
            <Container fluid={true}>
                <Row>
                    <BlogBox/>
                    <Col xl="6" className="box-col-12 xl-100" id="persp-container">
                        <Perspiciatis/>
                    </Col>
                    <Gallery/>
                </Row>
            </Container>
        </Fragment>
    );
};

export default BlogDetail;
