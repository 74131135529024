import { H6, P, UL, LI, Btn } from "../../../../AbstractElements";
import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import { Breadcrumbs } from "../../../../AbstractElements";

const JobDescription = () => {
	const [data, setDate] = useState([]);
	const [mustHaveData, setMustHave] = useState([]);
	const [skills, setSkills] = useState([]);
	const [error, setError] = useState({ error: false, message: "" });
	const [rating, setRating] = useState([]);

	let url = window.location.href;
	let jobId = url.substring(url.lastIndexOf(":") + 1);
	const API_BASE_URL = window.appConfig.API_BASE_URL;

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/Job/${jobId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				const jobList = res.data;
				setDate(jobList);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/JobMustHave/${jobId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				setMustHave(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		const authToken = sessionStorage.getItem("token");
		axios
			.get(`${API_BASE_URL}/JobSkill/${jobId}`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				setSkills(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<Fragment>
			<div className="job-description">
				<H6>Job Description</H6>
				<P attrPara={{ className: "text-start" }}>{data.jobSummary}</P>
			</div>
			<div className="job-description">
				<H6>Mandatory Requirements</H6>
				<UL>
					{mustHaveData.map((data, index) => (
						<LI key={index}>{data.name}</LI>
					))}
				</UL>
			</div>
			<div className="job-description">
				<H6>Skills</H6>
				<UL>
					{skills.map((data, index) => (
						<LI key={index}>{data.name}</LI>
					))}
				</UL>
			</div>
		</Fragment>
	);
};

export default JobDescription;
