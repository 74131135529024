import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import H3 from "../Headings/H3Element";

const BreadcrumbsNew = (props) => {
	return (
		<Fragment>
			<div className="page-header">
				<Row>
					<Col sm="6">
						<H3>{props.mainTitle}</H3>
					</Col>
					<Col sm="6">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active">{props.title}</li>
						</ol>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default BreadcrumbsNew;
