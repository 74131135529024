import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { BreadcrumbsNew } from "../../../../AbstractElements";
import ProfileEdit from "./EditProfile";

const EditProfile = () => {
	return (
		<Fragment>
			<Container fluid={true}>
				<BreadcrumbsNew title="My Profile" mainTitle="My Profile" />
				<ProfileEdit />
			</Container>
		</Fragment>
	);
};
export default EditProfile;
