import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Media } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import "./styles.css";

import {
	FormGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";

const ListOfImageDesc = () => {
	const [data, setDate] = useState([]);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [pageLoaded, setPageLoaded] = useState(false);
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	useEffect(() => {
		let isMounted = true;
		const authToken = sessionStorage.getItem("token");
		setModal(false);

		axios
			.get(`${API_BASE_URL}/SkillsTest/SkillsTestActive`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				if (isMounted) {
					setDate(res.data);
					setPageLoaded(true);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<Fragment>
			{!pageLoaded && (
				<div className="loader-box txt-primary">
					<div className="loader-7 txt-primary"></div>
				</div>
			)}
			{pageLoaded && (
				<Col sm="12">
					<Card>
						<CardBody className="my-gallery row gallery-with-description">
							{data.map(
								(
									{
										name,
										description,
										skillTestId,
										image,
										isBlocked,
										lastTaken,
									},
									index
								) => {
									return (
										<figure key={index} className="col-xl-3 col-sm-6">
											<div id="test-cont">
												<div id="test-image">
													<Media
														alt="Gallery"
														src={`${API_BASE_URL}/SkillsTest/SearchSkillTestLogo/${skillTestId}`}
														id="test-thumbnail"
														className="img-thumbnail"
													/>
												</div>
												<div id="test-body">
													<div className="caption">
														<h4>{name}</h4>
														<p>{description}</p>
													</div>
													<Link to={`/SkillTestConfirmation:${skillTestId}`}>
														{!isBlocked && (
															<Btn
																attrBtn={{
																	color: "primary",
																	className: "cancel-btn m-t-10",
																}}
															>
																Take Test
															</Btn>
														)}
													</Link>
													{isBlocked && (
														<Btn
															attrBtn={{
																className: "cancel-btn m-t-10 test-btn",
																onClick: toggle,
															}}
														>
															Completed {lastTaken.slice(0, 10)}
														</Btn>
													)}
												</div>
											</div>
										</figure>
									);
								}
							)}
						</CardBody>
					</Card>
				</Col>
			)}
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader>Test Already Completed</ModalHeader>
				<ModalBody>
					<FormGroup>
						<div className="App">
							Thank you for completing this test!
							<br />
							<br />
							SkilledUP has a 14 day waiting period between retaking tests.
							<br />
							<br />
							Please try again as soon as the test is available.
						</div>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Btn
						attrBtn={{
							color: "primary",
							className: "cancel-btn",
							onClick: toggle,
						}}
					>
						Cancel
					</Btn>
				</ModalFooter>
			</Modal>
		</Fragment>
	);
};

export default ListOfImageDesc;
