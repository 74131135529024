import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { BreadcrumbsNew } from "../../AbstractElements";
import CardsClass from "../JobSearch/JobCardView/Cards";

const Bulletin = () => {
	return (
		<Fragment>
			<Container fluid={true}>
				<BreadcrumbsNew title="Notifications" mainTitle="Notifications" />
				<CardsClass />
			</Container>
		</Fragment>
	);
};

export default Bulletin;
