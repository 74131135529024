import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { BreadcrumbsNew } from "../../../../AbstractElements";
import BulletinMainCard from "./MainCard";

const BulletinDetails = () => {
	return (
		<Fragment>
			<Container fluid={true}>
				<BreadcrumbsNew
					title="Notification Detail"
					mainTitle="Notification Detail"
				/>
				<Row>
					<BulletinMainCard />
				</Row>
			</Container>
		</Fragment>
	);
};

export default BulletinDetails;
