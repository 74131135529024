import React, { Fragment } from "react";
import { LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";

const LogoutClass = () => {
	const history = useNavigate();
	const token = sessionStorage.getItem("token");

	const Logout = () => {
		sessionStorage.removeItem("profileURL");
		sessionStorage.removeItem("token");
		sessionStorage.removeItem("auth0_profile");
		sessionStorage.removeItem("Name");
		sessionStorage.setItem("authenticated", false);
		history(`login`);
	};
	const clearSession = () => {
		sessionStorage.clear();
	};

	return (
		<Fragment>
			<LI attrli={{ className: "onhover-dropdown p-0", onClick: Logout }}>
				<Btn
					onClick={clearSession}
					attrBtn={{
						as: Card.Header,
						className: "btn btn-primary-light",
						color: "default",
					}}
				>
					<Link to={token ? `logout` : `login`}>
						<LogOut />
						{token ? "Log out" : "Log in"}
					</Link>
				</Btn>
			</LI>
		</Fragment>
	);
};

export default LogoutClass;
