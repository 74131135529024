export class ConfigDB {
	static data = {
		settings: {
			layout_type: "ltr",
			sidebar: {
				type: "compact-wrapper",
			},
			sidebar_setting: "default-sidebar",
		},
		color: {
			primary_color: "#e8a411",
			secondary_color: "#c29873",
			mix_background_layout: "dark-only",
		},
		router_animation: "fadeIn",
	};
}
export default ConfigDB;
