import axios from "axios";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, Container } from "reactstrap";
import { BreadcrumbsNew, Btn } from "../../AbstractElements";
import Timer from "../Timer";
import "./styles.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/ext-language_tools";

const SkillTest = (e) => {
	const [currentQuestion, setCurrentQuestion] = useState({
		question: "",
		answers: [],
	});
	const [isLastQuestion, setIsLastQuestion] = useState(false);
	const [isFirstQuestion, setIsFirstQuestion] = useState(false);
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const [testData, setTestData] = useState({
		questions: [],
	});
	const [questionCount, setQuestionCount] = useState(1);
	const [testLength, setTestLength] = useState(null);
	const [score, setScore] = useState(0);
	const [testResult, setTestResult] = useState(null);
	const [testName, setTestName] = useState("");
	const [pageLoaded, setPageLoaded] = useState(false);
	const [isNextDisabled, setIsNextDisabled] = useState(true);
	const [selectedAnswer, setSelectedAnswer] = useState(null);

	let url = window.location.href;
	let testId = url.substring(url.lastIndexOf(":") + 1);
	const hasCode = useMemo(() => {
		console.log({ currentQuestion });
		return (
			currentQuestion.questionCode && currentQuestion.questionCode.length > 0
		);
	}, [currentQuestion]);

	useEffect(() => {
		let isMounted = true;
		axios
			.get(`${API_BASE_URL}/SkillsTest/WIthQuestions/${testId}`)
			.then((res) => {
				if (isMounted) {
					setTestData(res.data);
					setTestLength(res.data.questions.length);
					setTestName(res.data.name);
				}
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (currentQuestion.answers.length > 0) {
			return;
		}
		if (testData.questions.length > 0) {
			setIsFirstQuestion(true);
			setCurrentQuestion(testData.questions[0]);
		}
	}, [testData]);

	const refreshButtonState = (currentQuestionIndex) => {
		setIsFirstQuestion(currentQuestionIndex == 0);
		setIsLastQuestion(currentQuestionIndex == testData.questions.length - 1);
	};

	const handleNextQuestion = () => {
		const currentQuestionIndex = testData.questions.findIndex(
			(elem) => elem.skillTestQuestionId == currentQuestion.skillTestQuestionId
		);

		if (currentQuestionIndex >= testData.questions.length - 1) {
			return;
		}
		const nextQuestion = testData.questions[currentQuestionIndex + 1];
		setCurrentQuestion(nextQuestion);
		refreshButtonState(currentQuestionIndex + 1);
		setQuestionCount(currentQuestionIndex + 2);
	};

	const handlePreviousQuestion = () => {
		const currentQuestionIndex = testData.questions.findIndex(
			(elem) => elem.skillTestQuestionId == currentQuestion.skillTestQuestionId
		);

		if (currentQuestionIndex <= 0) {
			console.log("done");
			return;
		}
		const nextQuestion = testData.questions[currentQuestionIndex - 1];
		setCurrentQuestion(nextQuestion);
		refreshButtonState(currentQuestionIndex - 1);
		setQuestionCount(currentQuestionIndex);
	};

	const submitTest = async () => {
		const authToken = sessionStorage.getItem("token");
		const testForm = document.getElementById("test-cont");
		testForm.classList.add("hidden");
		const resultArea = document.getElementById("result-container");
		resultArea.classList.add("display-results");

		const res = await axios.post(
			`${API_BASE_URL}/SkillsTest/SubmitTest`,
			testData,
			{
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			}
		);
		setTestResult(res.data.score);
		setPageLoaded(true);
	};

	// const testResultScreen = () => {
	// 	let resultText;

	// 	if (score <= 5) {
	// 		resultText =
	// 			"Your knowledge of the skillset is very low. You need to do more studying or courses to improve.";
	// 	} else if (score <= 10) {
	// 		resultText =
	// 			"Your knowledge of the skillset is low. Try doing a course to improve.";
	// 	} else if (score <= 15) {
	// 		resultText =
	// 			"You have some knowledge of the skillset but there is room for improvement.";
	// 	} else if (score <= 19) {
	// 		resultText = "Well done! You have good knowledge of the skillset";
	// 	} else {
	// 		resultText = "Excellent! You are an expert!";
	// 	}

	// 	return (
	// 		<Fragment>
	// 			<p className="test-result">{resultText}</p>
	// 		</Fragment>
	// 	);
	// };

	let keyItem = 0;

	return (
		<Fragment>
			<Container fluid={true}>
				<BreadcrumbsNew
					title="Assessment"
					mainTitle={`Assessment : ${testName}`}
				/>
				<Card id="test-cont">
					<CardHeader className="pb-0" id="card-container">
						<Card id="skill-card">
							<div className="top-container">
								<div className="question-container">
									<h6 className="skill-q-text">
										Question {questionCount} / {testLength} :
									</h6>
								</div>
								<div className="time-container">
									<h6 className="skill-time">
										Time Taken: <Timer startTime={new Date()} />
									</h6>
								</div>
							</div>
							<div className="question-text">
								<h6 id="question-q-text">{currentQuestion.question}</h6>
							</div>

							<br />

							{hasCode && (
								<AceEditor
									mode="javascript"
									value={currentQuestion.questionCode}
									name="code-editor"
									theme="twilight"
									editorProps={{ $blockScrolling: true }}
									showPrintMargin={false}
									fontSize="18px"
								/>
							)}

							<br />

							<hr className="skill-border" />
							<div className="answers-container">
								<ul className="ul-btn">
									{currentQuestion.answers.map((answer) => (
										<button
											className={"quiz" + (answer.selected ? " selected" : "")}
											key={keyItem++}
											type="button"
											onClick={() => {
												currentQuestion.answers.forEach(
													(a) => (a.selected = false)
												);
												answer.selected = true;
												currentQuestion.answerDate = new Date();
												setCurrentQuestion({ ...currentQuestion });
												setTestData({ ...testData });
											}}
										>
											{answer.answer}
										</button>
									))}
								</ul>
							</div>
							<div className="btn-container">
								<Btn
									attrBtn={{
										className: "btn text-center skill-test-left prev",
										color: "primary",
										type: "button",
										onClick: handlePreviousQuestion,
										disabled: isFirstQuestion,
									}}
								>
									Previous
								</Btn>
								<div className="test-spacer"></div>
								{!isLastQuestion && (
									<Btn
										attrBtn={{
											className: "btn text-center skill-test-right",
											color: "primary",
											type: "button",
											onClick: handleNextQuestion,
										}}
									>
										Next
									</Btn>
								)}
								{isLastQuestion && (
									<Btn
										attrBtn={{
											className: "btn text-center skill-test-right test-finish",
											color: "danger",
											type: "button",
											onClick: submitTest,
										}}
									>
										Submit
									</Btn>
								)}
							</div>
						</Card>
					</CardHeader>
				</Card>
				<Card id="result-container">
					{!pageLoaded && (
						<div className="loader-box txt-primary">
							<div className="loader-7 txt-primary"></div>
						</div>
					)}
					{pageLoaded && (
						<CardHeader className="pb-0">
							<Card id="skill-card-result">
								<div className="top">Thank you for completing the test!</div>
								<br />
								<div className="score-text">
									{Number(testResult).toFixed(2)} %
								</div>
								<br />
								{/* <p className="test-result">{testResultScreen()}</p> */}
								<Btn
									attrBtn={{
										className:
											"btn text-center skill-test-left prev result-btn",
										color: "primary",
										type: "button",
										onClick: handlePreviousQuestion,
										disabled: isFirstQuestion,
									}}
								>
									<Link to={`${process.env.PUBLIC_URL}/Skills-List`}>
										<p style={{ color: "#fff" }}>Back To Skills Tests</p>
									</Link>
								</Btn>
							</Card>
						</CardHeader>
					)}
				</Card>
			</Container>
		</Fragment>
	);
};

export default SkillTest;
