import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";
import "../JobSearch/JobCardView/Cards/styles.css";
import "./Modal.css";

const ModalAlert = ({ showModal, setShowModal, postMessage }) => {
	const toggle = () => {
		setShowModal(!showModal);
	};

	return (
		<div>
			{showModal && (
				<Modal size="md" isOpen={showModal} toggle={toggle}>
					<ModalHeader>Please sign in or register</ModalHeader>
					<ModalFooter>
						<Button id="cancel-mail" onClick={toggle}>
							Cancel
						</Button>
						<Link to={`/login`}>
							<Button color="primary" onClick={postMessage}>
								Sign In
							</Button>
						</Link>
						<Link to={`/Register`}>
							<Button color="primary" onClick={postMessage}>
								Register
							</Button>
						</Link>
					</ModalFooter>
				</Modal>
			)}
		</div>
	);
};

export default ModalAlert;
