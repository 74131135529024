import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { H6, P } from "../../AbstractElements";
import "./styles.css";

const Profile = () => {
	const candidateId = sessionStorage.getItem("candidateId");
	let isProfilePicUploaded = false;
	const API_BASE_URL = window.appConfig.API_BASE_URL;
	const token = sessionStorage.getItem("token");
	const [user, setUser] = useState({
		name: "",
		surname: "",
		gender: "",
		currentJobTitle: "",
	});

	const { name, surname, gender, currentJobTitle } = user;

	useEffect(() => {
		const loadUser = async () => {
			const email = sessionStorage.getItem("email");

			const result = await axios.get(
				`${API_BASE_URL}/Candidate/GetByEmail?email=${email}`
			);

			isProfilePicUploaded = result.data.avatar != null;
			setUser(result.data);
		};
		loadUser();
	}, []);

	return (
		<Fragment>
			{token ? (
				<div className="sidebar-user text-center">
					{user.avatar != null && (
						<img
							className={"img-90 rounded-circle user-pfp"}
							src={`${API_BASE_URL}/Candidate/GetAvatar/${candidateId}`}
						/>
					)}
					<H6
						attrH6={{
							className: "mt-3 f-14 f-w-600",
							id: "profile-header",
						}}
					>
						{`${name}${" "}${surname}`}
					</H6>
					<P
						attrPara={{
							className: "mb-0 font-roboto",
						}}
					>
						{currentJobTitle}
					</P>
				</div>
			) : (
				<div style={{ marginTop: "20px" }}></div>
			)}
		</Fragment>
	);
};

export default Profile;
